@import '../../../css/guideline.scss';

.page[name='payment-mail'] {
  &.page_preload {
    .grid-row {
      &__item {
        min-height: 600px;

        @extend %preloadStyle;
      }
    }
  }

  .page {
    &__title {
      margin-bottom: 24px;
    }
  }

  .info {
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: 24px;
    }

    &__fields {
      display: flex;
      flex-direction: column;

      .section-title {
        p {
          width: 100%;
        }
      }
      & > div {
        display: flex;

        @include media(
          (
            flex-direction: (
              mobileM: column
            )
          )
        );

        & > * {
          flex: 1;
        }

        & + * {
          margin-top: 16px;

          &:first-child {
            margin-top: 18px;
          }
        }
      }
    }
  }

  .total {
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: 24px;
    }

    &__text-button {
      display: inline-flex;
      align-self: center;

      text-align: center;
    }

    &__deadline {
      text-align: center;
    }

    &__fields {
      display: flex;
      flex-direction: column;

      & > div {
        display: flex;
        justify-content: space-between;

        width: 100%;

        & + div {
          margin-top: 12px;

          &:last-child {
            margin-top: 24px;
          }
        }
      }
    }
  }

  .grid-row {
    &__item {
      @extend %cardStyle;
      @include media(
        (
          padding: (
            all: 40px 48px,
            mobileL: 32px,
            mobileS: 24px
          )
        )
      );

      .button {
        @include media(
          (
            width: (
              mobileM: 100%
            )
          )
        );
      }
    }
  }
}
