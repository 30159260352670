@import '../../css/guideline.scss';

.institution {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @extend %cardStyle;

  &_preload {
    background: $preload;

    @include media(
      (
        height: (
          all: 310px,
          mobileL: 280px
        )
      )
    );
  }

  &__info,
  &__head {
    width: 100%;

    @include media(
      (
        padding: (
          all: 24px,
          mobileL: 16px 20px
        )
      )
    );
  }

  &__head {
    .typography + .typography {
      margin-top: 8px;
    }
  }

  &__info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-block {
      display: flex;

      .svg-image {
        @include media(
          (
            width: (
              all: 24px !important,
              mobileL: 20px !important
            ),
            min-width: (
              all: 24px !important,
              mobileL: 20px !important
            ),
            height: (
              all: 24px !important,
              mobileL: 20px !important
            )
          )
        );

        margin-right: 8px;
      }

      &:not(:first-child) {
        margin-top: 14px;
      }
    }
  }

  &__border {
    width: 100%;
    display: flex;
    border-bottom: 1px solid $border;
  }

  &__services {
    display: flex;

    @include media(
      (
        margin-top: (
          all: 24px,
          mobileL: 16px
        ),
        padding-bottom: (
          mobileL: 8px
        )
      )
    );

    & > * {
      @include media(
        (
          width: (
            mobileL: 32px !important
          ),
          height: (
            mobileL: 32px !important
          )
        )
      );

      & + * {
        @include media(
          (
            margin-left: (
              all: 16px,
              mobileL: 12px
            )
          )
        );
      }
    }
  }

  .typography.typography_type_text.typography_size_m {
    @include media(
      (
        line-height: (
          mobileL: 20px
        ),
        font-size: (
          mobileL: 14px
        )
      )
    );
  }
}
