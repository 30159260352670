@import '../../css/guideline.scss';

.html {
  &_preload {
    max-width: none;
    height: 800px;

    @extend %preloadStyle;
  }

  a {
    display: inline !important;
  }

  b,
  strong {
    color: $darkText;

    font-weight: 500;
  }

  .typography_color_warning {

    b,
    strong {
      color: $warningText;
    }
  }

  img {
    max-width: 100% !important;
    height: initial !important;

    object-fit: contain;
  }

  &:not(.html_special) {
    *+* {
      @include media((margin-top: (all: 24px,
            mobileL: 12px)));
    }

    .promo-widget {
      *+* {
        @include media((margin-top: (all: 0,
              mobileL: 0)));
      }
    }
  }

  a {
    display: flex;

    &+a {
      margin-top: 12px;
    }
  }

  ol {
    list-style: decimal;

    li {
      margin-left: 17px;
    }
  }

  h1,
  h2,
  h3 {
    &:not(:first-child) {
      @include media((margin-top: (all: 48px,
            mobileL: 24px)));
    }
  }
}
