.FormRegion {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none !important;
  margin: 0;
  padding: 0;

  &__item {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    width: calc(50% - 10px);
    height: 53px;
    padding: 16px 12px;
    background: #FFFFFF;
    border: 1px solid #C9CFDD;
    box-sizing: border-box;
    border-radius: 6px;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #3D4B66;
    cursor: pointer;
    &:hover{
      background-color: #EAF0FA;
    }
    & > span {
      &:last-child {
        color: #8B99AB;
        margin-left: 7px;
      }

      &:first-child {
        text-align: left;
      }
    }

    &:nth-child(odd) {
      margin-right: 20px;
    }
    &:nth-last-child(2), &:last-child {
      margin-bottom: 0;
    }

    @media screen and (max-width: 540px) {
      width: 100%;

      &:nth-child(odd) {
        margin-right: 0;
      }

      &:nth-last-child(2) {
        margin-bottom: 20px;
      }
    }
  }
}
