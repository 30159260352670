$color-education: #32C8B4;
$color-mail: #2E6BE5;
$color-photo: #FF9933;
$color-press: #F2B155;
$color-transfer: #3FCC6F;
$color-udo: #2EC7E5;
$color-video: #9B51E0;

.banner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 200px;
  overflow: hidden;
  margin-top: 25px;
  padding: 48px;
  position: relative;
  width: 100%;

  border: 1px solid #eff2f7;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 12px 24px rgba(18, 38, 63, .03);

  &__button {
    text-align: center;
    width: auto;

    &:hover {
      filter: brightness(90%);
    }
  }

  &__content {
    margin-bottom: 20px;
    margin-top: -20px;

    em {
      font-style: italic;
    }

    strong {
      font-weight: bold;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;

    &_icon {
      width: 35px;
      height: 35px;
    }

    &_service {
      padding-left: 5px;
      text-transform: uppercase;
    }
  }

  &__title {
    max-width: 495px;
    z-index: 0;
    padding-top: 18px;
    padding-bottom: 22px;
  }


  &--education {
    .banner {
      &__button {
        background-color: $color-education;
      }
      &__header_service {
        color: $color-education;
      }
    }
  }

  &--mail {
    .banner {
      &__button {
        background-color: $color-mail;
      }
      &__header_service {
        color: $color-mail;
      }
    }
  }

  &--photo {
    .banner {
      &__button {
        background-color: $color-photo;
      }
      &__header_service {
        color: $color-photo;
      }
    }
  }

  &--press {
    .banner {
      &__button {
        background-color: $color-press;
      }
      &__header_service {
        color: $color-press;
      }
    }
  }

  &--transfer {
    .banner {
      &__button {
        background-color: $color-transfer;
      }
      &__header_service {
        color: $color-transfer;
      }
    }
  }

  &--udo {
    .banner {
      &__button {
        background-color: $color-udo;
      }
      &__header_service {
        color: $color-udo;
      }
    }
  }

  &--video {
    .banner {
      &__button {
        background-color: $color-video;
      }
      &__header_service {
        color: $color-video;
      }
    }
  }
}