@import '../../../css/guideline.scss';
@import '../../../css/react-phone-input.scss';

$height: 48px;
$smallHeight: 40px;
$fontSize: 16px;
$padding: 0 12px;
$background: transparent;
$border: 1px solid $border;
$fontFamily: 'Roboto', sans-serif;

.form__element {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  &_small {
    &.form__element {
      .react-tel-input .flag-dropdown,
      input.form-control,
      input {
        height: $smallHeight;
      }
    }
  }

  ::placeholder {
    color: $lightText;
  }

  .validation-error {
    margin-top: 4px;
    color: $errorText;
    background: $background;
  }

  input,
  textarea {
    &:hover,
    &:focus {
      border-color: $darkBorder;
    }
  }

  input {
    &:not([value='']) {
      border-color: $border;
    }
  }

  textarea {
    &:not(:empty) {
      border-color: $border;
    }
  }

  input.form-control,
  input,
  textarea {
    width: 100%;
    height: $height;
    padding: 0 12px;

    transition: $transition;

    border: $border;
    border-radius: $borderRadiusFormElement;
    outline: none;
    background: $background;

    font-family: $fontFamily;
    font-size: $fontSize;

    &::placeholder {
      color: $lightText;
    }

    &:not(:disabled) {
      cursor: pointer;

      &:focus {
        cursor: text;
      }
    }
  }

  textarea {
    min-width: 100%;
    height: auto;
    min-height: 200px;
    padding-top: 20px;
    padding-bottom: 20px;

    resize: vertical;
  }

  &_checkbox {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    label {
      margin-top: -1px;

      cursor: pointer;
    }

    .checkbox-block {
      position: relative;

      display: flex;

      width: 20px;
      min-width: 20px;
      height: 20px;
      margin-right: 10px;

      input,
      .checkbox {
        position: absolute;
        z-index: 15;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        margin: 0;

        cursor: pointer !important;

        opacity: 0;
        border: none;
      }

      input:checked + .checkbox {
        border-color: $main;
        background: $main;
      }

      .checkbox {
        z-index: 10;

        display: flex;
        align-items: center;
        justify-content: center;

        transition: $transition;

        opacity: 1;
        border: $border;
        border-radius: 3px;

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  &_column {
    align-items: flex-start;
    flex-direction: column;

    .label {
      margin-bottom: 20px;
    }
  }

  &_dynamic {
    align-items: flex-start;
    flex-direction: column;

    :focus + label,
    &.form__element_error label,
    textarea:not(:empty) + label,
    input:not([value='']) + label {
      top: 0 !important;
      width: auto;
      p {
        color: $text !important;

        font-size: 12px !important;
      }
    }

    &.form__element_error label > p:first-child {
      color: $errorText !important;
    }

    label {
      position: absolute;
      top: 50%;
      left: 8px;

      padding: 0 4px;

      cursor: pointer;
      transition: $transition;
      transform: translateY(-50%);
      width: calc(100% - 50px);

      color: $lightText;
      border-radius: $borderRadiusFormElement;
      background-color: $white;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      font-size: 14px;

      p {
        transition: $transition;

        font-size: 16px !important;
      }
    }

    textarea + label {
      top: 24px;
    }
  }

  &__label {
    font-size: 16px;
  }

  &_round {
    .checkbox-block {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;

      input,
      .checkbox {
        border-radius: 100%;

        &::after {
          position: absolute;

          display: flex;

          width: 8px;
          height: 8px;

          content: '';

          border-radius: 100%;
          background: $white;
        }
      }
    }
  }

  &_error {
    input,
    textarea {
      color: $errorText;
      border-color: $errorText !important;
      background-color: rgba(135, 143, 164, 0.05) !important;
    }

    &.form__element_dynamic {
      textarea,
      input {
        background-color: transparent !important;
      }

      label p {
        color: $errorText !important;
      }
    }
  }

  .react-tel-input {
    display: flex;
    flex-direction: row-reverse;

    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .flag-dropdown {
      position: static !important;
      z-index: 20 !important;

      height: $height;
    }

    .country-list {
      position: absolute;

      width: 100%;
      margin: 2px 0 0 -1px !important;

      .divider {
        &:before {
          display: none;
        }
      }

      li {
        display: flex;
        align-items: center;

        height: 60px !important;
        margin: 0;
        &:before {
          margin-top: 0;
        }
      }
    }
  }
}
