@import '../../css/guideline.scss';

$imageSize: 120px;

.drop-zone {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  padding: 32px;

  border: 1px dashed $main;
  border-radius: 4px;
  margin-bottom:10px;
  position:relative;
  .loading{
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background-color:rgba(255,255,255,0.8);
    z-index: 10;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
  }
  &.red_line{
    border-color: red;
  }

  &_draggable {
    border-color: $accent;
  }

  .typography {
    max-width: 400px;

    text-align: center;
  }

  .button {
    max-width: 204px;
    margin-top: 32px;

    text-align: center;
  }

  .image-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    height:100%;
    & > * {
      padding: 10px;
    }

    & > div {
      position: relative;
      flex: 0 1 33.333333%;
      height:auto;
      @media(max-width:630px){
        flex-basis: calc(50%);
      }
      img{
        vertical-align: center;
      }
      & + div {
        width:auto;
        height:auto;
      }

      img {
        width: 100%;

        border-radius: 3px;

        object-position: 0 0;
        object-fit: cover;
      }

      .delete {
        transition: all .2s;
        position: absolute;
        top: 10px;
        right: 10px;
        left:10px;
        bottom:10px;
        opacity:0;
        cursor: pointer;
        border-radius: 2px;
        background: rgba($black, 0.25);
        display:flex;
        align-items: center;
        justify-content: center;
      }

      .delete::before{
        content:'x';
        font-size:20px;
        color:#fff;
        transform:translateY(-6%);
      }

      .delete:hover{
        opacity:1;
      }
    }

    .add-image {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 100px;
      height: 100px;

      cursor: pointer;

      border: 1px solid $main;
      border-radius: 10px;

      $size: 40%;

      & > div {
        position: absolute;

        background: $main;

        &:first-child {
          width: 1px;
          height: $size;
        }

        &:last-child {
          width: $size;
          height: 1px;
        }
      }
    }
  }
}
