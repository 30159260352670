@import '../../../css/guideline.scss';

$SIDEBAR_WIDTH: 300px;

.page[name='support'] {
  span {
    &.mail {
      color: $FSIN_MAIL;
    }
    &.transfer {
      color: $FSIN_TRANSFER;
    }
    &.tv {
      color: $FSIN_TV;
    }
    &.photo {
      color: $FSIN_PHOTO;
    }
    &.queue {
      color: $FSIN_QUEUE;
    }
  }

  #sticky-container {
    display: flex;

    @include media(
      (
        flex-direction: (
          tabletM: column-reverse,
          mobileL: column-reverse
        )
      )
    );

    .sticky-outer-wrapper {
      @include media(
        (
          width: (
            all: $SIDEBAR_WIDTH,
            tabletM: 100%,
            mobileL: 100%
          )
        )
      );
    }
  }

  .page {
    &__block {
      @include media(
        (
          margin-top: (
            all: 24px,
            mobileL: 12px
          )
        )
      );
    }

    &__sidebar {
      width: 100%;

      &-tabs {
        @include media(
          (
            margin-bottom: (
              all: 48px,
              tabletM: 32px,
              mobileL: 24px
            )
          )
        );

        & > .typography {
          cursor: pointer;

          & + .typography {
            @include media(
              (
                margin-top: (
                  all: 24px,
                  tabletM: 16px,
                  mobileL: 12px
                )
              )
            );
          }
        }
      }
    }

    &__content {
      flex: 1;

      @include media(
        (
          margin-left: (
            all: 80px,
            tabletM: 0,
            mobileL: 0
          ),
          margin-bottom: (
            tabletM: 32px,
            mobileL: 24px
          )
        )
      );

      & > div {
        & > .typography {
          @include media(
            (
              margin-bottom: (
                all: 24px,
                tabletM: 16px,
                mobileL: 12px
              )
            )
          );
        }

        & + div {
          @include media(
            (
              margin-top: (
                all: 48px,
                tabletM: 32px,
                mobileL: 24px
              )
            )
          );
        }

        .collapse-wrapper {
          overflow: hidden;

          background: $white;

          @extend %cardStyle;

          .ReactCollapse {
            &--content {
              border-top: none;
            }
          }

          & > .collapse {
            border: none;
            border-radius: 0;

            & + .collapse {
              &:before {
                display: flex;

                width: calc(100% - 48px);
                height: 1px;
                margin: 0 auto;

                content: '';

                background: $lightBorder;
              }
            }
          }
        }
      }
    }
  }
}
