@import '../../css/guideline.scss';

.collapse {
  .ReactCollapse {
    &--collapse {
      transition: height 500ms;
    }

    &--content {
      border-top: 1px solid $lightBorder;

      @include media(
        (
          padding: (
            all: 24px 24px,
            mobileL: 16px 20px
          )
        )
      );
    }
  }
}

.collapse {
  display: flex;
  flex-direction: column;

  width: 100%;

  @extend %cardStyle;

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;

    @include media(
      (
        padding: (
          all: 20px 24px,
          mobileL: 16px 20px
        )
      )
    );

    i {
      transition: $transition;
    }
  }

  &_active {
    .collapse {
      &__title {
        i {
          transform: rotate(-180deg);
        }
      }
    }
  }

  &_disabled {
    .ReactCollapse {
      &--content {
        border: none;
      }
    }

    .collapse {
      &__title {
        cursor: default;
      }
    }
  }
}
