@import '../../../css/guideline.scss';

$SIDEBAR_WIDTH: 352px;

.page[name='index-mail'] {
  .form__element_error{
    display:flex;
    flex-direction: column;
    align-items: baseline;
    p{
      text-align:left;
    }
  }
  .grayscale{
    img {
      filter: grayscale(100%); 
    }
  }

  #sticky-container {
    display: flex;

    @include media(
      (
        flex-direction: (
          tabletM: column,
          mobileL: column
        )
      )
    );

    .sticky-outer-wrapper {
      @include media(
        (
          width: (
            all: $SIDEBAR_WIDTH,
            tabletM: 100%,
            mobileL: 100%
          ),
          min-width: (
            all: $SIDEBAR_WIDTH,
            tabletM: 100%,
            mobileL: 100%
          ),
          margin-left: (
            all: 32px,
            tabletM: 0
          ),
          margin-top: (
            tabletM: 32px
          )
        )
      );
    }
  }

  .page {
    &__content {
      width: 100%;

      & > div {
        @include media(
          (
            padding: (
              all: 40px 48px,
              mobileL: 32px,
              mobileS: 24px
            )
          )
        );
        @extend %cardStyle;

        .form__group {
          margin-top: 24px;
        }

        & + div {
          margin-top: 22px;
        }
      }
    }

    &__sidebar {
      &-form {
        display: flex;
        flex-direction: column;

        @include media(
          (
            padding: (
              all: 40px 48px,
              mobileL: 32px,
              mobileS: 24px
            )
          )
        );
        @extend %cardStyle;

        & > p.typography {
          margin: 0 auto;
          margin-top: 24px;

          text-align: center;

          @include media(
            (
              max-width: (
                all: 200px,
                tabletM: 270px
              )
            )
          );

          a {
            display: inline;
          }
        }

        &-calculate {
          display: flex;
          flex-direction: column;

          margin-top: 24px;
          margin-bottom: 16px;

          span {
            span {
              color: $black;
            }
          }

          & > div + div {
            margin-top: 12px;
          }

          .total {
            align-items: center;

            margin-top: 24px;
          }

          & > * {
            display: flex;
            align-items: center;
            justify-content: space-between;

            & + * {
              margin-top: 4px;
            }

            & > * {
              display: flex;
              align-items: center;
            }

            input {
              max-width: 90px;
              margin-right: 8px;
              padding-right: 10px;
              padding-left: 0px;

              text-align: right;

              &:disabled {
                border-color: transparent;
              }
            }
          }
        }
      }

      &-services {
        margin-top: 24px;

        & > * + * {
          margin-top: 24px;
        }
      }
    }
  }

  .counter {
    display: flex;

    margin-top: 12px;
    & > * + * {
      margin-left: 10px;
    }
  }

  .title-block {
    display: flex;
    align-items: center;

    & + * {
      margin-top: 24px;
    }

    span {
      display: flex;

      & > * + * {
        margin-left: 5px;
      }
    }

    & > * + * {
      margin-left: 10px;
    }
  }


  .counter-block {
    display: inline-flex;
    align-items: center;

    margin-top: 0 !important;
    padding: 10px;

    border: 1px solid $border;
    border-radius: 4px;

    @include media(
      (
        margin-top: (
          all: 0 !important,
          mobileM: 16px !important
        )
      )
    );

    &> * {
      width: 20px;

      text-align: center;
    }
  }

  .blanks-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media(
      (
        flex-direction: (
          mobileM: column !important
        )
      )
    );
  }
  
  .form {
    display: flex;

    width: 100%;

    & > * + * {
      margin-top: 0;
    }

    .form__group {
      @include media(
        (
          flex-direction: (
            tabletS: column !important
          )
        )
      );

      & > * + * {
        @include media(
          (
            margin-left: (
              tabletS: 0
            ),
            margin-top: (
              tabletS: 16px
            )
          )
        );
      }

      & + .form__group {
        @include media(
          (
            margin-top: (
              tabletS: 16px
            )
          )
        );
      }
    }
  }
}
