@import '../../css/guideline.scss';

.service {
  position: relative;

  display: flex;
  align-items: flex-start;

  border-radius: $border-radius;
  background: $white;
  box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.03);
  .promo{
    margin-top:15px;
    margin-bottom: -10px;
  }
  .name_description {
    text-transform: none;
  }
  .button_size {
    white-space: nowrap;
    @include media(
      (
        padding: (
          all: 12px 16px,
          tabletM: 10px 12px,
          mobileL: 6px 10px,
        ),
        font-size: (
          all: 16px,
          tabletM: 14px,
          mobileL: 12px
        )
      )
    );
  }
  @extend %cardStyle;
  @include media(
    (
      padding: (
        all: 48px,
        tabletM: 32px,
        mobileL: 20px
      ),
      min-height: (
        all: 200px,
        mobileL: 0
      )
    )
  );

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
  }

  &__name {
    display: flex;
    align-items: center;

    text-transform: uppercase;

    .svg-image {
      margin-right: 12px;
    }
  }

  &__sub-name{
    text-transform: none;
    @include media(
      (
        display: (
          all: 'none',
          tabletM: 'block',
          mobileL: 'block'
        )
      )
      );
  }

  &__force-visible{
    display: unset !important;
  }

  &__header{
    position: absolute;
    top: 0;
    right: 24px;
    padding: 0 16px;
    background: rgba(155, 81, 224, 0.1);
    font-size: 12px;
    line-height: 24px;
    border-radius: 0px 0px 8px 8px;

    @include media(
    (
      display: (
        tabletM: 'none',
        mobileL: 'none'
      )
    )
    );
  }

  &__description,
  &__name {
    position: relative;
    z-index: 5;
  }

  &__background {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;

    display: flex;

    @include media(
      (
        display: (
          mobileL: none !important
        )
      )
    );

    svg {
      width: 200px;
      height: 200px;
    }
  }

  &__description {
    @include media(
      (
        margin-top: (
          all: 24px,
          mobileL: 16px
        ),
        max-width: (
          all: 300px,
          tabletM: 400px,
          mobileL: 360px
        ),
        width: (
          all: calc(100% - 120px),
          tabletM: calc(100% - 180px),
          mobileL: 100%
        ),
        line-height: (
          mobileL: 20px !important
        ),
        font-size: (
          mobileL: 14px !important
        )
      )
    );
  }

  &__message {
    margin-top: 48px;

    @include media(
      (
        margin-top: (
          all: 48px,
          tabletM: 32px,
          mobileL: 16px
        )
      )
    );
  }

  &_mail {
    .service__name {
      span {
        color: $main;
      }
    }
  }

  &_photo {
    .service__name {
      span {
        color: $FSIN_PHOTO;
      }
    }
  }

  &_transfer {
    .service__name {
      span {
        color: $FSIN_TRANSFER;
      }
    }
  }

  &_phone{
    .service__name{
      span{
        color:#F23071;
      }
    }
  }

  &_video_talks {
    .service__name {
      span {
        color: $FSIN_VIDEO_TALKS;
      }
    }
  }

  &_press {
    .service__name {
      span {
        color: $FSIN_PRESS;
      }
    }
  }

  &_queue {
    .service__name {
      span {
        color: $FSIN_QUEUE;
      }
    }
  }

  &_short {
    @extend %cardStyle;
    @include media(
      (
        padding: (
          all: 24px,
          mobileL: 20px
        ),
        min-height: (
          all: 0 !important
        )
      )
    );
  }

  &_withoutLink {
    opacity: 0.75;
  }

  &_withoutImage {
    .service__description{
      max-width:none;
    }
    p {
      width: 100%;
    }
  }
  
  &_withoutImage {
    @include media(
      (
        padding: (
          all: 40px 48px,
          tabletM: 32px,
          mobileL: 20px
        )
      )
    );
  }

  &_disabled {
    .service__content {
      height: 100%;
    }

    .service__name {
      span {
        color: $text;
      }
    }

    .svg-image {
      circle {
        fill: $lightText;
      }
    }
  }

}

.lower_case{
  text-transform: none;
}

.press_label {
  position: absolute;
  top: 0;
  right: 30px;
  padding: 0 16px;
  background-color: #9B51E01A;
  border-radius: 0px 0px 8px 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #295FCC;

}
