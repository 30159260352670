@import '../../../css/guideline.scss';

.page[name='institutions'] {
  .wrapper {
    // .section-title {
    //   @include media(
    //     (
    //       height: (
    //         // all: aur,
    //         // mobileL: 24px
    //       )
    //     )
    //   );
    // }

    .search {
      @include media(
        (
          margin-top: (
            all: 28px,
            tabletM: 16px,
            mobileL: 0px
          )
        )
      );
    }

    .regions {
      display: block;

      @include media(
        (
          margin-top: (
            all: 40px,
            tabletM: 32px,
            mobileL: 24px
          ),
          column-count: (
            all: 3,
            tabletM: 2,
            mobileL: 1
          ),
          column-gap: (
            all: 32px,
            mobileL: 0
          )
        )
      );

      .region {
        & + .region {
          margin-top: 16px;
        }
      }
    }
    
    .page-description{
      @include media(
        (
          margin-top: (
            all: 40px,
            tabletM: 32px,
            mobileL: 24px
          ),
        )
      )
    }

    .grid {
      display: flex;

      @include media(
        (
          margin-top: (
            all: 32px,
            mobileL: 16px
          )
        )
      );
    }

    .region-info {
      width: 100%;

      @include media(
        (
          padding: (
            all: 48px,
            tabletS: 24px
          )
        )
      );
      @extend %cardStyle;

      &:not(:first-child) {
        margin-top: 48px;
      }

      table {
        @include media(
          (
            margin-top: (
              all: 48px,
              tabletS: 24px
            )
          )
        );

        td:first-child {
          @include media(
            (
              padding-right: (
                all: 200px,
                tabletM: 100px
              )
            )
          );
        }

        tr {
          @include media(
            (
              display: (
                tabletS: flex
              ),
              flex-direction: (
                tabletS: column
              )
            )
          );

          &:not(:last-child) {
            @include media(
              (
                padding-bottom: (
                  tabletS: 16px
                )
              )
            );

            td {
              @include media(
                (
                  padding-bottom: (
                    all: 16px,
                    tabletS: 0
                  )
                )
              );
            }
          }
        }
      }
    }
  }
}
