@import '../../css/guideline.scss';

.question-chat {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: content-box;

  @include media(
    (
      align-items: (
        tabletM: center,
        mobileL: center
      ),
      text-align: (
        tabletM: center,
        mobileL: center
      )
    )
  );

  & > .typography {
    @include media(
      (
        max-width: (
          mobileL: 200px
        )
      )
    );
  }

  .button {
    @include media(
      (
        margin: (
          all: 24px 0,
          mobileL: 12px 0
        )
      )
    );
  }
}
