@import '../../css/guideline.scss';

.grid-row {
  display: flex;

  width: 100%;

  @include media(
    (
      flex-direction: (
        all: row,
        tabletM: column,
        mobileL: column
      )
    )
  );

  & + & {
    @include media(
      (
        margin-top: (
          all: 32px,
          mobileL: 24px,
          mobileS: 16px
        )
      )
    );
  }

  & > * {
    flex: 1;

    & + * {
      @include media(
        (
          margin-left: (
            all: 32px,
            tabletM: 0,
            mobileL: 0
          ),
          margin-top: (
            tabletM: 24px,
            mobileL: 24px,
            mobileS: 16px
          )
        )
      );
    }
  }
}
