@import '../../css/guideline.scss';

.page {
  position: relative;

  display: flex;
  align-items: center;
  flex: 1 0 auto;
  flex-direction: column;

  width: 100%;

  background: $lightBackground;
}
