.document-view {
  padding: 16px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.03);
  // padding-bottom: 16px;
  border: 1px solid #EFF2F7;

  &__download-button {
    border: none;
    padding-left: 0px;

  }

  &__popup {
    margin-top: unset !important;
    width: unset !important;
    height: unset !important;
  }

  &__popup-container {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }


  &__service-link {
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    min-width: 70px;
    padding: 5px 10px;
    padding-right: 5px;
    border: 1px solid #EFF2F7;
    border-radius: 5px;
    transition: .3s linear;

    p {
      display: flex;
    }

    .svg-image {
      width: 24px;
      height: 24px;
      transition: .3s linear;

    }


    &:hover {
      background-color: #fff;
      box-shadow: 0px 7px 14px rgba(18, 38, 63, 0.1);

      p {
        color: #4d4848;
        font-weight: 500;
        cursor: pointer;
      }

      .svg-image {
        width: 0;
        height: 0;
      }
    }
  }
}

.doc {}
