@import '../../../css/guideline.scss';

.service-icon {
  display: flex;
  border-radius: 100%;
  align-items: center;
  justify-content: center;

  @include media(
    (
      width: (
        all: 48px,
        mobileL: 40px
      ),
      height: (
        all: 48px,
        mobileL: 40px
      ),
      min-width: (
        all: 48px,
        mobileL: 40px
      )
    )
  );

  svg {
    width: 100% !important;
    height: 100% !important;
  }
}
