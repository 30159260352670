@import '../../css/guideline.scss';

*{
  &:focus{
    outline: rgba(153, 153, 153, 0.933) solid 1px ;
    outline:none ;
  }
}

.comments{
  width: 100%;
  display: flex;
  padding-top: 72px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  &__header_line{
    width: 100%;
    border: none;
    border-bottom: 1px solid $darkBorder;
    margin-bottom: 40px;
  }

  &__header{
    margin-bottom: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &_text{
      @include media(
        (
          font-size: (
            all: 24px,
            mobileL: 16px !important
          )
        )
      );
    }
  }

  &__header_value{
    padding-left: 10px;
    color: #7A8599;
  }

  &__sort_button{
    border: none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media(
      (
        display: (
          all: flex,
          mobileL: none
        )
      )
    );
    &.stick_to_right{
      margin-left: auto;
    }
  }

  &__sort_icon.rotate{
    transform: rotate(180deg);
  }

  &__list{
    width: 100%;
    @include media(
      (
        margin-top: (
          all: 64px,
          mobileL: 24px
        )
      )
    );
  }

  &_item{
    &:before{
      display: none;
    }
  
    width: 100%;
    padding: 20px 0;
    list-style-type: none;
    &>div{
      width: 100%;
    }
  }

  &__form{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media(
      (
        flex-direction: (
          all: row,
          mobileL: column
        ),
        align-items: (
          all: center,
          mobileL: flex-start
        )
      )
    );

  }

  &__input{
    border-radius: 5px;
    border: 1px solid #E0E6ED;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 16px;
    padding-right: 70px;
    width: 100%;
    color: $darkText;
    @include media(
      (
        display: (
          all: block,
          mobileL: none
        )
      )
    );
  }

  &__textArea{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    border-radius: 5px;
    border: 1px solid #E0E6ED;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 16px;
    padding-right: 70px;
    width: 100%;
    color: $darkText;
    resize: none;
    @include media(
      (
        display: (
          all: block,
          mobileL: block
        ),
        height: (
          all: auto,
          mobileL: 144px
        ),
        margin-top: (
          all: auto,
          mobileL: 16px
        )
      )
    )
  }

  &__auth_message{
    color: #ACB3BF;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: -32px;
    right: 0;
  }

  &__auth_link{
    color: #37578f;
  }

  &__show_more {
    box-sizing: border-box;
    padding: 20px;
    background: none;
    border: none;
    border: 1px solid #E0E6ED;
    border-radius: 10px;
    @include media(
      (
        align-self: (
          all: flex-start,
          mobileL: center
        )
      )
    )
  }

  &__lds{
    align-self: center;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: rgb(92, 96, 100);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

