@import '../../../../../css/guideline.scss';

.MenuRegistration {
  margin: 0px 0 20px;
  box-shadow: inset 0 -1px 0 0 #e0e6ed;
  padding-bottom: 1px;
  
  &__list {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow-x: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    & > li{
      list-style: none;
    }
  }

  &__item {
    position: relative;
    cursor: pointer;
    display:flex;
    align-items: center;
    svg {
      margin: 2px 12px 0px 12px;
      @media(max-width:768px){
        margin: 0 8px 0px;
      }
    }

    &:last-of-type svg {
      display: none;
    }

    &_active {
      & button {
        box-shadow: inset 0 -2px 0 0 #295fcc;
      }
    }
  }

  &__link {
    border: none;
    cursor: pointer;
    background-color: transparent;
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    padding: 15px 0;
    color: #3d4b66;
    text-decoration: none;
    outline: none;
    box-shadow: none;
    @media screen and (max-width: 768px) {
      font-size: 14px;
      line-height: 24px;
    }
  }
}
