@import '../../../css/guideline.scss';

$MAX_WIDTH: 736px;

.page[name='article'] {
  .promo-widget{
    margin-top: 25px;
  }
  .comments{
    padding-top:25px;
  }
  .page_preload {
    display: flex;
    flex-direction: column;
    width: $MAX_WIDTH;

    .page {
      &__button {
        width: 140px;
        height: 48px;

        @extend %preloadStyle;
      }

      &__tag {
        width: 200px;
        height: 30px;

        @extend %preloadStyle;
      }

      &__title {
        height: 80px;

        @extend %preloadStyle;
      }

      &__info {
        width: 70%;
        height: 32px;

        @extend %preloadStyle;
      }

      &__photo {
        width: 100%;
        margin-top: 28px;
        padding-bottom: 56.25%;

        @extend %preloadStyle;
      }
    }
  }
}

.page[name='article'] {
  .html {
    margin-top: 24px;
  }

  .wrapper {
    flex-direction: column;
    justify-content: space-between;

    @include media(
      (
        flex-direction: (
          all: row,
          tabletM: column
        )
      )
    );

    & > * {
      width: $MAX_WIDTH;
      max-width: 100%;
    }
  }

  .page {
    &__tag {
      @include media(
        (
          margin-top: (
            all: 24px,
            mobileL: 12px
          )
        )
      );
    }

    &__title {
      @include media(
        (
          margin-top: (
            all: 16px,
            mobileL: 8px
          )
        )
      );
    }

    &__info {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      margin-bottom: -12px;

      @include media(
        (
          margin-top: (
            all: 18px,
            mobileL: 16px
          ),
          margin-right: (
            all: -24px,
            mobileL: -16px
          )
        )
      );

      & > * {
        display: flex;
        align-items: center;

        @include media(
          (
            margin-right: (
              all: 24px,
              mobileL: 16px
            ),
            margin-bottom: (
              all: 12px
            )
          )
        );
      }

      i {
        margin-right: 4px;
      }
    }

    &__comments {
      margin-top: 48px;
      padding-top: 48px;

      color: $border;
      border-top: 1px solid $border;
    }

    &__article {
      @include media(
        (
          margin-top: (
            // all: -342px,
              // all: -782px,
              tabletM: 0,
            // mobileL: 16px
          )
        )
      );
    }
    &__services {
      display: flex;
      flex-direction: column;
      position: sticky;
      padding-left: 36px;
      top: 30px;
      right: 40px;
      align-self: flex-start;

      @include media(
        (
          display: (
            all: flex,
            tabletM: flex,
            mobileL: none
          ),
          position: (
            all: sticky,
            tabletM: static,
          ),
          width: (
            all: 33%,
            tabletM: 100%,
          ),
          min-width: (
            all: 170px,
            tabletM: auto,
          ),
          margin-bottom: (
            all: 0,
            tabletM: 0,
          ),
          padding-left: (
            all: 48px,
            tabletM: 0
          ),
          padding-top: (
            all: 0,
            tabletM: 32px
          )
        )
      );

      & > * {
        @extend %cardStyle;
        & + * {
          @include media(
            (
              margin-top: (
                all: 24px,
                tabletM: 16px,
                mobileL: 16px
              )
            )
          );
        }
        &:hover{
          box-shadow: 0px 12px 24px rgba(14, 31, 53, 0.1);
        }      
      }
    }
    &__linksToRelatedArticles {
      border: 1px solid rgb(221, 220, 220);

      hr {
        border: none; 
        color: #dddcdc; 
        background-color: #dddcdc; 
        height: 2px; 
      }

      a {
        padding-top: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        i,
        img {
          box-sizing: border-box;
          width: 63px;
          padding-right: 3%;
        }

        &:hover {
          color: #1e252e;
        }
      }
    }
    &__companiesInfo {
      background-color: rgb(248, 248, 248);
      border: 1px solid rgb(221, 220, 220);

      p {
        padding: 10px 0;
      }

      &__blockInfo {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;

        div {
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            padding-right: 10px;
          }
        }

        &__adress {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      
      &__fullName {
        p {
          padding: 0;
        }
      }
    }
  }
}
