@import '../../../css/guideline.scss';

$SIDEBAR_WIDTH: 300px;

.page[name='custom'] {
  .html {
    margin-top: 24px;
  }

  .preloader {
    display: flex;

    width: 100%;
    height: 800px;

    @extend %preloadStyle;
  }

  .faq {
    display: flex;
    ul{
      li{
        display:flex;
        flex-direction: column;
        &::before{
          display:none;
        }
      }
    }
    $SIDEBAR_WIDTH: 300px;
    @include media(
      (
        flex-direction: (
          tabletM: column-reverse,
          mobileL: column-reverse
        )
      )
    );

    .sticky-outer-wrapper {
      @include media(
        (
          width: (
            all: $SIDEBAR_WIDTH,
            tabletM: 100%,
            mobileL: 100%
          )
        )
      );
    }

    .ReactCollapse--collapse {
      overflow: hidden;
      display: none;
      min-width: 100%;
      transition: all linear .5s;

      .html {
        margin-top: 0;
      }
    }

    span {
      &.mail {
        color: $FSIN_MAIL;
      }
      &.transfer {
        color: $FSIN_TRANSFER;
      }
      &.tv {
        color: $FSIN_TV;
      }
      &.photo {
        color: $FSIN_PHOTO;
      }
      &.queue {
        color: $FSIN_QUEUE;
      }
    }

    .page {
      &__description {
        // margin: 24px 0;
  
        a {
          margin-left: 5px;
          display: inline-block;
          font-weight: bold;
          // &:last-child{
          //   margin: 0;
          // }
          &:hover {
            color: #1e252e;
          }
        }
  
        .description__union {
          margin-left: 5px;
        }
  
        &:nth-child(3){
          a{
            margin: 0;
          }
        }
  
      }
  
      &__block {
        @include media(
          (
            margin-top: (
              all: 24px,
              mobileL: 12px
            )
          )
        );
      }

      &__sidebar {
        width: 100%;
        padding-bottom: 36px;

        &-tabs {
          display: flex;
          flex-direction: column;  
          @include media(
            (
              margin-bottom: (
                all: 48px,
                tabletM: 32px,
                mobileL: 24px
              )
            )
          );

          & > .typography {
            cursor: pointer;
            width: 160px;
            padding: 10px;
            text-align: center;


            & + .typography {
              @include media(
                (
                  margin-top: (
                    all: 24px,
                    tabletM: 16px,
                    mobileL: 12px
                  )
                )
              );
            }

            &.active{
              border: 1px solid #2352af;
              border-radius: 5px;  
            }
  
          }
        }
      }
      &__content {
        & > div {
          & > .typography {
            @include media(
              (
                margin-bottom: (
                  all: 24px,
                  tabletM: 16px,
                  mobileL: 12px
                )
              )
            );
          }

          & + div {
            @include media(
              (
                margin-top: (
                  all: 48px,
                  tabletM: 32px,
                  mobileL: 24px
                )
              )
            );
          }

          .collapse-wrapper {
            overflow: hidden;

            background: $white;

            @extend %cardStyle;

            .ReactCollapse {
              &--content {
                border-top: none;
              }
            }

            & > .collapse {
              border: none;
              border-radius: 0;

              & + .collapse {
                &:before {
                  display: flex;

                  width: calc(100% - 48px);
                  height: 1px;
                  margin: 0 auto;

                  content: '';

                  background: $lightBorder;
                }
              }
            }
          }
        }
      }


      &__wrapper {
        flex: 1;

        @include media(
          (
            margin-left: (
              all: 80px,
              tabletM: 0,
              mobileL: 0
            ),
            margin-bottom: (
              tabletM: 32px,
              mobileL: 24px
            )
          )
        );

        & > div {
          & > .typography {
            @include media(
              (
                margin-bottom: (
                  all: 24px,
                  tabletM: 16px,
                  mobileL: 12px
                )
              )
            );
          }

          & + div {
            @include media(
              (
                margin-top: (
                  all: 48px,
                  tabletM: 32px,
                  mobileL: 24px
                )
              )
            );
          }

          .collapse-wrapper {
            overflow: hidden;

            background: $white;

            @extend %cardStyle;

            .ReactCollapse {
              &--content {
                border-top: none;
              }
            }

            & > .collapse {
              border: none;
              border-radius: 0;

              & + .collapse {
                &:before {
                  display: flex;

                  width: calc(100% - 48px);
                  height: 1px;
                  margin: 0 auto;

                  content: '';

                  background: $lightBorder;
                }
              }
            }
          }
        }
      }
    }
  }
}
