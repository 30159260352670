@import '../../../css/guideline.scss';

$SIDEBAR_WIDTH: 352px;

.page[name='documents'] {
  .page_preload {
    .grid {
      margin-top: 32px;
    }

    .page {
      &__title {
        width: 300px;
        max-width: 80%;
        height: 40px;

        @extend %preloadStyle;
      }

      &__tabs {
        height: 28px;
        @extend %preloadStyle;
      }
    }
  }

}

.page[name='documents'] {
  .page__title {
    @include media((margin-bottom: (all: 48px,
          mobileL: 28px)));

    .typography {
      align-items: flex-end;
    }
  }

  .page_preload {
    display: flex;

    &>div {
      display: flex;
    }

    .page {
      &__title {
        width: 300px;
        max-width: 80%;
        height: 40px;
        @include media((margin-bottom: (all: 48px,
              mobileL: 28px)));

        @extend %preloadStyle;
      }

      &__sidebar {
        width: $SIDEBAR_WIDTH;

        &-services {
          &>* {
            @extend %preloadStyle;
          }
        }

        &-navigation {
          @extend %preloadStyle;

          &>div {
            @extend %preloadStyle;
          }
        }

      }

      &__content {
        &>* {
          height: 66px;

          @extend %preloadStyle;

          &:first-child {
            height: 400px;

            @extend %preloadStyle;
          }
        }
      }
    }
  }

  .page {

    &__tabs .tab-slider__tabs,
    &__services {
      transform: none;
      width: 320px;
      min-width: 320px;
      margin-right: 48px;

      @include media((width: (all: 320px,
            tabletM: 100%,
            tabletS: 100%,
            mobileL: 100%)));

    }

    &__tabs {
      padding-top: 36px;
    }

    &__tabs .tab-slider__content {
      background: #FFFFFF;
    }

    &__service {
      margin-top: 24px;

      @include media((display: (all: block,
            tabletM: none,
            tabletS: none,
            mobileL: none)));

    }

    &__tabs .tab-slider__tabs {
      background: #FFFFFF;
      box-shadow: 0px 9px 14px rgba(10, 4, 81, 0.02), 0px 2.71324px 4.22059px rgba(10, 4, 81, 0.0130318), 0px 1.12694px 1.75302px rgba(10, 4, 81, 0.01), 0px 0.407592px 0.634032px rgba(10, 4, 81, 0.00696822);
      border-radius: 8px;
      padding: 16px;
    }

    &__content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      padding: 40px 48px;
    }

    &__content-group {
      padding-bottom: 16px;

      h2 {
        margin-bottom: 40px;
      }

      @include media((width: (all: 100%,
            mobileL: 100%)));
    }
  }

  .tab-slider__content {
    margin-left: 0px !important;
  }
}
