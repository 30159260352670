@import '../../css/guideline.scss';

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 12px 16px;

  cursor: pointer;
  transition: $transition;

  color: $white;
  border: none;
  border-radius: $borderRadiusFormElement;
  outline: none !important;
  background: $main;

  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 24px;

  .loader {
    width: 24px;
    height: 24px;
  }

  &:focus {
    outline: none;
  }

  .svg-image {
    margin-right: 4px;
  }

  &_red {
    background: rgba(229,53,53,1);
  }

  &_orange{
    background: $FSIN_PHOTO;
  }

  &_white {
    background: white !important;
    color: $main;
  }

  &_blue {
    background: $main;
  }

  &_green {
    background: $FSIN_TRANSFER;
    
    &:hover {
      background: darken($FSIN_TRANSFER, 10%);
    }
  }

  &_dark_green{
    background: #47B247;
  }

  &_full_width{
    width:100%;
  }

  &_only-back {
    width: 48px;
    height: 48px;
    
    .svg-image {
      margin-right: 0px;
    }
  }
  
  &_transparent {
    color: $text;
    border: 1px solid $border;
    background: transparent;

    line-height: 22px;

    &.button {
      &_blue {
        color: $main;
        border-color: $main;
      }
      
      &_green {
        color: $FSIN_TRANSFER;
        border-color: $FSIN_TRANSFER;
      }
    }
  }

  &_outline {
    color: $text;
    border: 1px solid $main;
    background: transparent;
    padding: 12px 24px;

    line-height: 22px;

    &.button {
      &_blue {
        color: $main;
        border-color: $main;
      }

      &_green {
        color: $FSIN_TRANSFER;
        border-color: $FSIN_TRANSFER;
      }
    }
    &.button_disabled{
      color: $text;
      cursor:no-drop;
    }
  }
  &_disabled{
    color: $text;
  }
}
