.ContainerWrapper {
  width: 100%;
  &__title {
    margin: 0 0 20px 0;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    cursor: default;
    @media screen and (max-width: 768px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__description {
    font-size: 16px;
    line-height: 32px;
    color: #7a8599;
    margin: 0 0 20px 0;
    cursor: default;
  }
}
