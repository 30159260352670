.sending-contact {
  border: 1px solid #E1E7EE;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  
  &__full-name {
    padding-left: 8px;
  }
  &__selected {
    // border: 1px solid #E0E6ED;

  }
  &__container {
     padding: 24px 16px 24px 26px;
     display: flex;
     flex-direction: row;

     text-overflow: ellipsis;
     overflow: hidden;
  }
  
}