@import '../../css/guideline.scss';

.comment{
  width: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  &*{
    &:focus{
      outline: rgba(136, 135, 135, 0.933);
    }
  }

  &__header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__sort_button{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__content{
    padding: 8px 0;
    white-space: normal;
    word-wrap: break-word;

    @include media(
      (
        margin-left: (
          all: auto,
          mobileL: 50px
        )
      )
    );
  }

  &__action{
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span{
      padding: 0 10px;
    }

    @include media(
      (
        margin-left: (
          all: auto,
          mobileL: 45px
        )
      )
    );
  }
  
  &__rating{
    color:#ACB3BF;

    &.up{
      color:#47B347
    }

    &.down{
      color:#E61739
    }
  }

  &__replies_drop{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    margin-left: 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    background: none;
    border: none;
    color: #383838;

    &:hover{
      color: darken($color: #ACB3BF, $amount: 40%)
    }

    &_icon.up{
      transform: rotate(180deg);
    }
  }
}

.item {
  &__header{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &__buttonViewBody{
    margin: 10px 0;
    padding: 5px;
    border: none;
    background: none;
    border: 1px solid #ACB3BF;
    border-radius: 4px;
  }
}

.replies__item{
  margin-left: 50px;
  padding: 30px 0;

  &:before{
    content: none;
  }

  &>div{
    width: 100%;
  }
}

.logo_user{
  flex: none;
  position: relative;
  font-family: 'Roboto';
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: #7A8599;
  box-sizing: border-box;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #E0E6ED;

  &::before{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    position: absolute;
    white-space: nowrap;
    left:50px;
    content: attr(data-firstname);
    @include media(
      (
        display: (
          all: none,
          mobileL: block
        )
      )
    );
  
  }
  &__no-avatar .svg-image {
    width: 30px;
    height: 30px;
  }
  & img {
    border-radius: 50%;
    
  }
}

.action{
  &__answer,&__rating{
    background: none;
    border: none;
    color: #ACB3BF;

    &:hover{
      color: darken($color: #ACB3BF, $amount: 40%)
    }
  }

  &__dislike_icon.rotate{
    transform: rotate(180deg);
  }
}

.since_creation{
  color: #ACB3BF;
  margin-left: 5px;
  font-family: Roboto;
  font-style: normal;
  font-size: 12px;
  margin-top: 2px;
}

.comment, .action, .comments{ 
  button:not(.comments__send){
    cursor: pointer;
    &:hover{
      color: darken($color: #ACB3BF, $amount: 20%);
      & i svg path {
        fill:darken($color: #ACB3BF, $amount: 20%);
      }
    }

    &:focus{
      color: darken($color: #ACB3BF, $amount: 40%);
      & i svg path {
        fill:darken($color: #ACB3BF, $amount: 40%);
      }
    }
    &.comments__show_more{
      &:focus{
          border: 1px solid darken($color: #E0E6ED, $amount: 80%);
      }
    }
  }
}

.comment, .action, .comments{ 
  button{
    &:disabled{
      cursor: not-allowed;
      color: darken($color: #c9cfd8, $amount: 20%);
      & i svg path {
        fill:darken($color: #c9cfd8, $amount: 20%);
      }
    }
  }
}