@import '../../css/guideline.scss';
.popup{
    display:none;
    opacity:0;
    position:absolute;
    background: #ffffff;
    box-shadow: 0px 1.49996px 10.5887px rgba(10, 4, 81, 0.0242336), 0px 4.28585px 17.1763px rgba(10, 4, 81, 0.0357664), 0px 9px 31px rgba(10, 4, 81, 0.06);
    border-radius: 4px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    z-index:101;
    &.show{
        display:block;
        opacity:1;
    }
    &.top{
        top:calc(100%);
    }
    &.bottom{
        bottom:calc(100% + 15px);
    }
    &.left{
        left:calc(100% + 15px);
    }
    &.right{
        right:calc(100% + 15px);
    }
    .link{
        border-bottom: 1px solid #E0E6ED;
        padding-left:12px;
        height: 48px;
        &:last-child{
            border-bottom:none;
        }
        &:hover {
            background: rgba(41, 95, 204, 0.05);
      
            p {
              color: $main !important;
            }
      
          }
        svg{
            margin-right:12px;
        }
    }
}