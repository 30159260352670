@import '../../css/guideline.scss';
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');

.typography {
  font-family: 'Roboto', sans-serif;

  &_clickable {
    cursor: pointer;
  }

  &_color {
    &_dark-green{
      color: #2b8f4c;
    }

    &_white {
      color: $white;
    }

    &_black {
      color: $black;
    }

    &_dark-text {
      color: $darkText;
    }

    &_light-text {
      color: $lightText;
    }

    &_text {
      color: $darkText;
    }

    &_border {
      color: $border;
    }

    &_red {
      color: $errorText;
    }

    &_warning {
      color: $warningText;
    }

    &_green {
      color: $FSIN_TRANSFER;
    }

    &_main {
      color: $main;
    }

    &_gray {
      color: #7A8599;
    }
  }

  &.typography_type_heading {
    font-weight: 500 !important;

    &.typography_size {
      &_2xl {
        @include media((line-height: (all: 40px,
              tabletM: 32px,
              mobileL: 24px),
            font-size: (all: 32px,
              tabletM: 24px,
              mobileL: 20px)));
      }

      &_xl {
        font-size: 24px;
        line-height: 32px;
      }

      &_l {
        @include media((font-size: (all: 20px,
              mobileL: 16px),
            line-height: (all: 24px,
              mobileL: 20px)));
      }

      &_m {
        font-size: 16px;
        line-height: 20px;
      }

      &_s {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  &.typography_type_text {
    &.typography_size {
      &_2xl {
        font-size: 32px;
        line-height: 40px;
      }

      &_xl {
        font-size: 24px;
        line-height: 32px;
      }

      &_l {
        @include media((font-size: (all: 18px,
              tabletM: 16px),
            line-height: (all: 28px,
              mobileL: 24px)));
      }

      &_m {
        font-size: 16px;
        line-height: 24px;
      }

      &_s {
        font-size: 14px;
        line-height: 20px;
      }

      &_xs {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  &_uppercased {
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }

  &.typography {
    &_mark {
      &_medium {
        font-weight: 500 !important;
      }

      &_regular {
        font-weight: 400 !important;
      }

      &_italic {
        font-style: italic;
      }
    }
  }
}
