@import '../../css/guideline.scss';

.section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  word-break: break-word;

  .typography {
    display: flex;
    align-items: center;

    @include media(
      (
        font-size: (
          mobileL: 20px !important
        ),
        line-height: (
          mobileL: 24px !important
        )
      )
    );

    & > .link:first-child {
      min-width: 129px;
      margin-right: 24px;
    }

    & > span:first-child {
      margin-right: 6px;
    }
  }

  &__additional {
    display: flex;
    align-items: center;
  }
}
