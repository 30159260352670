@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap&subset=cyrillic");
.new_service_card {
  font-family: Roboto, 'Roboto Rouble', sans-serif;
  cursor: pointer;
  display: flex;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0px 0.40759px 0.63403px 0px rgba(10, 4, 81, 0.01), 0px 1.12694px 1.75302px 0px rgba(10, 4, 81, 0.01), 0px 2.71324px 4.22059px 0px rgba(10, 4, 81, 0.01), 0px 9px 14px 0px rgba(10, 4, 81, 0.02);
  .blue {
    .service_text {
      color: #2E6BE6;
    }
  }

  .green {
    .service_text {
      color: #3FCC6F;
    }
  }

  .orange {
    .service_text {
      color: #FFB31A;
    }
  }

  .purple {
    .service_text {
      color: #9B51E0;
    }
  }

  .activeButton {
    .service_button {
      border-color: transparent !important;
      background-color: #3FCC6F;
      color: #fff !important;
    }
  }

  .with_bg {
    @media(min-width: 1024px) {
      padding-right: 0 !important;
    }
  }

  .service_body {
    position: relative;
    height: 100%;
    display: flex;
    width: 100%;
    
    flex-direction: column;
    padding: 40px 48px;
    
    @media(max-width: 1024px) {
      padding: 22px 20px;
    }

    .service_title {
      display: flex;
      margin-bottom: 24px;
      @media(max-width: 1024px) {
        margin-bottom: 10px;
      }
      .service_icon {
        width: 48px;
        height: 48px;
        margin-right: 12px;
      }
      .service_text {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        text-transform: uppercase;
      }
    }

    

    .service_description {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 24px;
      color: #7A8599;
      @media(max-width: 1024px) {
        margin-bottom: 10px;
        font-size: 14px;
      }
    }

    .service_button_wrapper {
      display: flex;
      justify-items: flex-start;
      .service_button {
        padding: 12px 24px;
        align-items: center;
        border-radius: 4px;
        border-width: 1px;
        border-style: solid;
        border-color: #295FCC;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #295FCC;
        @media(max-width: 1024px) {
          width: 100%;
        }
      }
    }
  }

  .service_bg_image {
     > img {
      width: 208px;
     }
    
    @media(max-width: 1024px) {
      display: none;
    }
  }

  .service_bg_image_mobile {
    display: none;
    justify-content: center;
    > img {
      height: 124px;
      margin-bottom: 10px;
    }
   
    @media(max-width: 1024px) {
      display: flex;
    }
  }
}