@import "../../css/guideline.scss";

.navigation {
  position: relative;
  z-index: 101;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 72px;
  .dark-text {
    color: $darkText;
  }
  .flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .link_udo{
    cursor:pointer;
    & + .link_udo {
      margin-left: 24px;
    }
    &_active{
      color: #295fcc;
      position:relative;
      &::before{
        content: '';
        position:absolute;
        border-bottom: 3px solid #295fcc;
        top: calc(100% + 4px);
        width:100%;
      }
    }
  }
  &.fixed_nav-udo{
    position:fixed;
    width:100%;
    top:0;
    left:0;
    box-shadow: 0 1px 10px rgba(0,0,0,0.2);
  }
  &__search-dark-theme,
  &__burger,
  &__person-ic-dark-theme {
    margin-left: 16px;

    svg {
      path {
        fill: #ffffff;
      }
    }
  }
  &__avatar {
        border-radius: 24px;
        width: 48px;
        height: 48px;
        object-fit: cover;
        overflow: hidden;
        margin-left: 8px;
      }
  &__user-info-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
      }
      &__user-info-and-popup-container {
            display: flex;
            justify-content: flex-end;
          }
  &__transrarent_text {
    color: transparent;
    margin: 0;
    font-size: 0;
  }
  &__registration-button-white {
    max-height: 48px;
    text-align: center;
    color: #ffffff !important;
    border-color: #ffffff !important;
    text-align: center;
  }
  &__search,
  &__person-ic {
    margin-left: 16px;

    svg {
      path {
        fill: #7A8599;
      }
    }
  }
  .header__telefon.typography_color_dark-text {
    @include media(
      (
        color: (
          tabletS: $white,
          mobileL: $white,
        ),
      )
    );
  }
  &__telefon {
    width: max-content;
    padding: 0 20px;

    @include media(
      (
        display: (
          all: flex,
          mobileS: none,
          mobileM: none,
          mobileL: none,
        ),
        position: (
          all: static,
          tabletM: absolute,
          tabletS: static,
        ),
        right: (
          all: auto,
          tabletM: 29px,
          tabletS: auto,
        ),
        bottom: (
          all: auto,
          tabletM: -22px,
          tabletS: auto,
        ),
      )
    );
    @media (max-width: 1024px) {
      position: absolute;
      right: -20px;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
  &__border-shadow {
    @media (max-width: 768px) {
      background-color: #fff;
    }
  }
  &__burger {
    margin-left: 16px;

    svg {
      path {
        fill: #ffffff;
      }
    }
  }
  @include media(
    (
      height: (
        all: $headerHeight,
        tabletS: $tabletHeaderHeight,
        mobileL: $mobileHeaderHeight,
      ),
    )
  );

  &:not(.index_page) {
    @include media(
      (
        background-color: (
          all: $white,
        ),
      )
    );
  }
  .main-link {
    position: relative;

    transition: $transition;
    transition-property: border-color;
    cursor:pointer;
    border-bottom: 3px solid transparent;
    &.link_active {
      color: #295fcc;
      border-color: #295fcc;
    }
    & + .main-link {
      margin-left: 24px;
    }

    &_active {
      color: $main;
      border-color: $main;
    }
  }
  &_links {
    height: 100%;
    @keyframes showNav {
      0% {
        display: block;
        top: 0;
        opacity: 0;
      }
      100% {
        top: 100%;
        opacity: 1;
      }
    }
    &.show {
      animation: showNav 0.3s;
      @media (max-width: 768px) {
        box-shadow: inset 0px 0.407592px 0.634032px rgba(10, 4, 81, 0.00696822),
          inset 0px 1.12694px 1.75302px rgba(10, 4, 81, 0.01), inset 0px 2.71324px 4.22059px rgba(10, 4, 81, 0.0130318),
          inset 0px 9px 14px rgba(10, 4, 81, 0.02);
        display: flex !important;
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: -60px;
        padding-top: 10px;
        background-color: #fff;
        width: 100vw;
        height: 100vh;
        .main-link {
          margin-left: 60px;
          margin-right: 60px;
          padding-top: 16px;
          padding-bottom: 16px;
          color: #3d4b66;
          font-size: 16px;
          line-height: 24px;
        }
      }
      @media (max-width: 601px) {
        left: -34px;
        .main-link {
          margin-left: 34px;
          margin-right: 34px;
          padding-top: 16px;
          padding-bottom: 16px;
        }
      }
      @media (max-width: 412px) {
        left: -24px;
        .main-link {
          margin-left: 24px;
          margin-right: 24px;
          padding-top: 12px;
          padding-bottom: 12px;
        }
      }
      @media (max-width: 320px) {
        left: -16px;
        .main-link {
          margin-left: 16px;
        }
      }
    }
    .typography_color_dark-text {
      @include media(
        (
          color: (
            tabletS: $white,
            mobileL: $white,
          ),
        )
      );
    }

    path {
      @include media(
        (
          fill: (
            tabletS: $white,
            mobileL: $white,
          ),
        )
      );
    }
  }

  .button_outline {
    &.flex {
      text-align: center;
      @include media(
        (
          text-align: (
            text-align: center,
          ),
          padding: (
            tabletM: 3px 8px,
            all: auto,
          ),
          margin-left: (
            tabletM: 20px,
            all: auto,
          ),
          line-height: (
            all: auto,
            tabletM: 1rem,
          ),
          font-size: (
            all: 16px,
            tabletM: 12px,
          ),
        )
      );
    }
    &.button_blue {
      @include media(
        (
          color: (
            tabletS: $white,
            mobileL: $white,
          ),
          border-color: (
            tabletS: $white,
            mobileL: $white,
          ),
        )
      );
    }
  }

  .wrapper {
    height: 100%;

    // .burger__line {
    //   &:after,
    //   &:before {
    //     @include media(
    //       (
    //         background-color: (
    //           tabletS: $white,
    //           mobileL: $white,
    //         ),
    //       )
    //     );
    //   }

    //   @include media(
    //     (
    //       background-color: (
    //         tabletS: $white,
    //         mobileL: $white,
    //       ),
    //     )
    //   );
    // }

    .burger_show .burger__line {
      &:after,
      &:before {
        @include media(
          (
            background-color: (
              tabletS: #3d4b66,
              mobileL: #3d4b66,
            ),
          )
        );
      }

      @include media(
        (
          background-color: (
            tabletS: #3d4b66,
            mobileL: #3d4b66,
          ),
        )
      );
    }

    .typography {
      @include media(
        (
          font-size: (
            mobileL: 16px,
          ),
          line-height: (
            mobileL: 13px,
          ),
        )
      );
    }
  }
  &__main {
    height: 100%;
    width: 100%;
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  &__logotype {
    margin-right: 10px;
    .svg-image {
      @include media(
        (
          margin-right: (
            tabletS: 16px,
            mobileL: 10px,
          ),
          width: (
            tabletS: 36px,
            mobileL: 36px,
          ),
          height: (
            tabletS: 32px,
            mobileL: 32px,
          ),
        )
      );

      svg {
        @include media(
          (
            width: (
              mobileL: 100%,
            ),
            height: (
              mobileL: 100%,
            ),
          )
        );
        path {
          @media (min-width: 768px) {
            fill: #fff !important;
          }
        }
      }
    }
  }
  &__popup {
    position: absolute;
    top: 100%;
  }
  &__arrow {
    cursor: pointer;
    &-white {
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
  &__logo-and-services {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 36px;
  }

  &_service {
    &_transfer {
      .main-link {
        &_active {
          color: $FSIN_TRANSFER;
          border-color: $FSIN_TRANSFER;
        }
      }
    }
    &_mail {
      .link {
        &_active {
          color: $main;
          border-color: $main;
        }
      }
    }
  }
}

.no-scroll {
  margin: 0;
  overflow: hidden;
  height: 100%;
}
