.sending-sender-form {
  &__existing-contact{
    margin-top: 16px;
    width: 100%;
  }
  &__existing-contacts-container{
    padding: 0;
  }
  &__button{
    margin-top: 16px;

  }
  &__select-button{
    margin-top: 6px;
    padding-left: 0;
  }
}