@import '../../../css/guideline.scss';

.queue{
    li{
        margin-bottom: 20px !important;
    margin-top:0px;
    list-style: none !important;
        &:before{
            content: none !important;
            display:none !important;
          }
    }
    .page{
        &> .wrapper{
            @media only screen and (max-width: 600px){
                margin-top:0 !important;
            }

        }
        &__content{
            width: 100%;
            margin-bottom: 48px;
            padding: 40px 48px 48px;
            border: 1px solid #eff2f7;
            box-sizing: border-box;
            box-shadow: 0 12px 24px rgba(18, 38, 63, 0.03);
            border-radius: 6px;
            background: #fff;
            @media screen and (max-width: 768px) {
                padding: 20px 20px 32px;
            }
            @media screen and (max-width: 540px) {
                padding: 16px 20px 24px;
            }
        }
        &__sidebar{
            .sidebar_row{
                display:flex;
                flex: 1 1;
                flex-direction: row;
                @media(max-width: 768px){
                    flex-direction: column;
                }
                &:nth-child(1){
                    margin-bottom:22px;
                }
                .service{
                    width:50%;
                    @media(max-width: 768px){
                        width:100%;
                        margin-bottom:16px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                    &:nth-child(1){
                        
                        margin-right:32px;
                        @media(max-width: 768px){
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}


.PeriodVisit {
    &__list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      list-style: none;
      margin: 20px 0 0;
      padding: 0;
    }
  
    &__item {
      width: calc(25% - 15px);
      padding: 12px 14px 14px;
      background: #ffffff;
      border: 1px solid #c9cfdd;
      box-sizing: border-box;
      border-radius: 6px;
      margin-bottom: 20px;
      margin-right: 20px;
      line-height: 20px;
      color: #3D4B66;
      cursor: pointer;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
  
      &:hover {
        background: #E8F1FF;
        border-color: #9BB2E0;
      }
  
      &:nth-child(4n+4) {
        margin-right: 0;
      }
  
      &_disabled {
        color: #949CAB;
        background: #F9F9FB;
        border: none;
        &:hover {
          background: #F9F9FB;
        }
      }
  
      &_active {
        background: #E2FBEB;
        border-color: #A2DCB7;
  
        &:hover {
          background: #E2FBEB;
          border-color: #A2DCB7;
        }
      }
  
      @media screen and (max-width: 768px) {
        width: calc(33% - 12px);
        &:nth-child(4n+4) {
          margin-right: 20px;
        }
        &:nth-child(3n+3) {
          margin-right: 0;
        }
      }
  
      @media screen and (max-width: 540px) {
        width: calc(50% - 6px);
        margin-bottom: 12px;
        margin-right: 12px;
  
        &:nth-child(3n+3) {
          margin-right: 12px;
        }
        &:nth-child(even) {
          margin-right: 0;
        }
      }
    }
  }
  