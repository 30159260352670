@import '../../css/guideline.scss';

$size: 24px;
$color: #7A8599;
$line-width: 18px;
$border-weight: 2px;
$line-distance: 5px;
$close-rotation: 135deg;

.burger {
  width: $size;
  height: $size;
  // overflow: hidden;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &__line {
    top: 50%;
    left: 3px;
    width: $line-width;
    position: absolute;
    background: $color;
    height: $border-weight;
    transform: translate(0%, -50%);

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: $line-width;
      transition: all 0.2s;
      height: $border-weight;
      background-color: $color;
      transform: translateX(0%);
      min-height: $border-weight;
    }

    &::before {
      top: (-$line-distance);
    }

    &::after {
      top: $line-distance;
    }
  }

  &_show {
    .burger__line {
      background-color: transparent !important;

      &::before,
      &::after {
        top: 0;
        left: -2px;
        transition: all 0.2s;
        width: calc(#{$line-width} + 4px);
      }

      &::before {
        transform: rotate(-$close-rotation);
      }

      &::after {
        transform: rotate($close-rotation);
      }
    }
  }

  &_dark {
    &__line {
      background: #ffffff;

      &::before,
      &::after {
        background-color: #ffffff;
      }
    }
  }
}
