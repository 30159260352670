@import '../../css/guideline.scss';

$bottom: 32px;
$transition: background 250ms, transform 500ms, opacity 500ms;

.notification {
  position: fixed;
  z-index: $zIndexModal + 10;
  bottom: $bottom;
  left: 50%;

  box-sizing: border-box;
  max-width: 90%;
  padding: 8px 15px;

  transition: $transition;
  transform: translate(-50%, 100%);
  text-align: center;

  opacity: 0;
  border-radius: 20px;
  background: $main;

  &.isVisible {
    transform: translate(-50%, 0);

    opacity: 1;
  }

  &.isPlayerShow {
    bottom: $bottom;
  }

  &.isUpdate {
    background: $FSIN_MAIL;
  }
}
