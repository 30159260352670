@import '../../css/guideline.scss';

.footer {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;

  width: 100%;

  background: $darkBackground;

  .logotype {
    margin-bottom: 20px;
  }

  .button.button_transparent {
    border-color: $text;

    @include media((width: (mobileL: 100%)));
  }

  &>div {
    &:not(.wrapper) {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      width: 100%;
    }
  }

  &__navigation {
    @include media((padding-top: (all: 64px,
          mobileL: 24px),
        padding-bottom: (all: 64px,
          mobileL: 24px)));

    &>div {
      @include media((display: (mobileL: flex),
          flex-direction: (mobileL: column),
          flex: (mobileL: 1),
          align-items: (mobileL: center),
          justify-content: (mobileL: center)));

      &:nth-child(1) {
        display: flex;
        flex: 1;
        justify-content: center;

        margin-left: 20px;

        &>div {
          display: flex;
          align-items: flex-start;
          flex-direction: column;

          width: 50%;

          .typography {
            margin-top: 16px;
          }
        }
      }
    }
  }

  &__social {
    @include media((margin: (all: 48px 0,
          mobileL: 24px 0)));

    .typography {
      margin-bottom: 8px;
    }

    &>div {
      display: flex;
      align-items: center;

      @include media((justify-content: (mobileL: center)));

      .svg-image+.svg-image {
        margin-left: 8px;
      }
    }
  }

  &__payment {
    display: flex;

    padding-top: 24px;
    padding-bottom: 24px;

    border: 1px solid rgba(255, 255, 255, 0.15);
    border-right: none;
    border-left: none;

    .svg-image {
      height: 32px;

      &+.svg-image {
        margin-left: 50px;

        @include media((margin-left: (all: 50px,
              tabletM: auto)));
      }
    }
  }

  &__copyright {
    flex-direction: column;
    justify-content: space-between;

    @include media((padding-top: (all: 32px,
          mobileL: 0),
        padding-bottom: (all: 32px,
          mobileL: 24px)));

    &>div {
      display: flex;
      align-items: center;
      // justify-content: center;
      

      color: $white;

      &>* {
        margin-top: 10px;
      }

      @media(max-width: 1050px){
        flex-wrap: wrap;
        justify-content:center
      }

      @include media((flex: (mobileL: 1),
          flex-direction: (mobileL: column),
          align-items: (mobileL: center),
          justify-content: (mobileL: center)));

      .typography+.typography {
        @include media((margin-left: (all: 30px,
              mobileL: 0),
            margin-top: (all: 10px,
              mobileL: 12px)));
      }
    }

    &>p {
      padding-top: 20px;
      align-self: flex-end;
    }
  }
}
