@import '../../css/guideline.scss';

.form {
  & > * + * {
    @include media(
      (
        margin-top: (
          all: 24px,
          mobileL: 12px
        )
      )
    );
  }
}
