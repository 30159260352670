.FormCalendar {
  &__description {
    font-size: 16px;
    line-height: 32px;
    color: #7a8599;
    margin: 0 0 20px 0;
    cursor: default;
  }

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    width: calc(25% - 15px);
    padding: 12px 14px 14px;
    background: #ffffff;
    border: 1px solid #c9cfdd;
    border-radius: 6px;
    margin-bottom: 20px;
    margin-right: 20px;
    line-height: 20px;
    color: #7a8599;
    cursor: pointer;
    display:flex;
    flex-direction: column;

    &:hover {
      background: #E8F1FF;
      border-color: #9BB2E0;
    }

    h5 {
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: #3d4b66;
      margin: 0 0 2px 0;
    }

    p {
      margin: 0 0 10px 0;
      font-size: 16px;
      line-height: 20px;
      color: #7a8599;

      &:last-child {
        margin-bottom: 0;
        font-size: 14px;
        line-height: 16px;
      }
    }

    &:nth-child(4n+4) {
      margin-right: 0;
    }

    &_disabled {
      background: #f9f9fb;
      border: none;

      &:hover {
        background: #f9f9fb;
      }

      h5,
      p {
        color: #949cab;
      }
    }

    &_active {
      background: #E2FBEB;
      border-color: #A2DCB7;

      &:hover {
        background: #E2FBEB;
        border-color: #A2DCB7;
      }
    }

    @media screen and (max-width: 768px) {
      width: calc(33% - 12px);
      &:nth-child(4n+4) {
        margin-right: 20px;
      }
      &:nth-child(3n+3) {
        margin-right: 0;
      }
    }

    @media screen and (max-width: 540px) {
      width: calc(50% - 6px);
      margin-bottom: 12px;
      margin-right: 12px;

      &:nth-child(3n+3) {
        margin-right: 12px;
      }
      &:nth-child(even) {
        margin-right: 0;
      }
    }
  }

  &__month-name {
    text-transform: capitalize;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #3c4858;
    margin-bottom: 12px;
  }

  &__visit-select {
    margin-bottom: 20px;
  }
}
