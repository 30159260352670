.FormConfirm {
  &__info {
    h5 {
      margin: 24px 0 16px;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      color: #3C4858;
    }
  }

  &__item {
    display: grid;
    grid-template-columns: minmax(100px, 1fr) minmax(200px, 2fr);
    word-wrap: break-word;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    & > span {
      &:first-of-type {
        font-size: 14px;
        line-height: 20px;
        color: #7A8599;
      }
      &:last-of-type {
        font-size: 16px;
        line-height: 20px;
        color: #3D4B66;
      }
    }

    @media screen and (max-width: 768px) {
      display: block;
      & > span {
        display: block;
      }
    }
  }

  &__checkbox-wrap {
    margin: 28px 0 24px;
  }

  &__checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 8px 0;
    color: #7A8599;
    a {
      margin-left: 5px;
    }
  }

  &__error {
    font-size: 12px;
    color: #E61739;
    margin-bottom: 10px;
  }
}
.star_error{
  color:red;
}