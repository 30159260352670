@import './media.scss';
@import './colors.scss';

@keyframes background {
  100% {
    transform: translateX(-66.6666%);
  }
}

// ----------

$maxWidthWrapper: 1120px;
$wrapperPaddingAll: 80px;
$wrapperPaddingDesktopS: 36px;
$wrapperPaddingTabletM: 48px;
$wrapperPaddingTabletS: 60px;
$wrapperPaddingMobileL: 34px;
$wrapperPaddingMobileM: 24px;
$wrapperPaddingMobileS: 16px;

// ----------

$zIndexHeader: 100;
$zIndexMobileMenu: 99;

$zIndexValidation: 50;
$zIndexSelect: 51;
$zIndexSelectActive: 52;
$zIndexModal: 1000;

// ----------

$border-radius: 6px;
$borderRadiusFormElement: 4px;

$transition: all 250ms;
$box-shadow: 0 5px 22px 0 rgba(0, 0, 0, 0.065);

// HEIGHTS ===============================

$headerHeight: 72px;
$tabletHeaderHeight: 76px;
$mobileHeaderHeight: 56px;

%cardStyle {
  border: 1px solid $lightBorder;
  border-radius: $border-radius;
  background: $white;
  box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.03);
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.75;
  }
}

%preloadStyle {
  // transition: $transition;
  animation: 1.5s pulse infinite;

  border-color: transparent !important;
  border-radius: $border-radius;
  background: $preload;
}

%indexHeadAnimation {
  background: #202040;

  &:before {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;

    align-self: flex-start;

    width: 300%;
    height: 100%;

    content: '';
    transform: translateX(0%);
    animation: background linear 15s infinite;

    background: linear-gradient(
      90deg,
      #202040 0,
      #220066 33.3333%,
      #202040 66.6666%,
      #220066 100%
    );
    background-position: 0 0;
  }
}
