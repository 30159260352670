@import '../../../css/guideline.scss';

.form__group {
  display: flex;

  @include media(
    (
      flex-direction: (
        mobileL: column
      )
    )
  );

  &_column {
    flex-direction: column;
  }

  & > * {
    margin-top: 0;

    & + * {
      @include media(
        (
          margin-left: (
            all: 24px,
            mobileL: 0
          ),
          margin-top: (
            mobileL: 12px
          )
        )
      );
    }
  }
}
