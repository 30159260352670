@import '../../css/guideline.scss';

$borderWidth: 1px;
$borderRadius: $borderRadiusFormElement;

$itemColor: $text;
$activeItemColor: $white;
$activeItemBackground: $main;
$hoverItemBackground: $lightBorder;

.select {
  position: relative;
  z-index: $zIndexSelect;

  display: inline-flex;
  flex: 1;

  height: 48px;
  min-height:48px;
  border: none !important;
  border-color: transparent;
  outline: none;

  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 24px;

  &,
  & * {
    cursor: pointer !important;

    outline: none;
  }

  & > div {
    width: 100%;
  }

  .select {
    &__control {
      border: $borderWidth solid $border;
      border-radius: $borderRadius;
      box-shadow: none;

      &:hover {
        border-color: $darkBorder;
      }

      &--is-focused {
        .select__single-value {
          color: $lightText;
        }
      }

      &--menu-is-open {
        border-color: $darkBorder;

        .select {
          &__indicator {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    &__option {
      overflow: hidden;

      height: 36px;
      padding: 6px 12px;

      transition: $transition;
      white-space: nowrap;
      text-overflow: ellipsis;

      color: $itemColor;

      font-size: 16px;

      &:hover,
      &--is-focused {
        background-color: $hoverItemBackground;
      }

      &--is-selected {
        color: $activeItemColor !important;
        background-color: $activeItemBackground !important;
      }
    }

    &__value-container {
      padding-left: 12px;

      & > div {
        margin-right: 0;
        margin-left: 0;
      }
    }

    &__input {
      &-element {
        box-sizing: content-box;
        width: 2px;
        padding: 0px;

        opacity: 1;
        color: inherit;
        border: 0px;
        outline: 0px;
        background: 0px center;

        font-size: inherit;
      }

      &-value {
        position: absolute;
        top: 0px;
        left: 0px;

        visibility: hidden;
        overflow: scroll;

        height: 0px;

        white-space: pre;
        letter-spacing: normal;
        text-transform: none;

        font-family: system-ui;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
      }
    }

    &__menu {
      margin-top: 10px;
      border-radius: $borderRadius;
      box-shadow: 0 12px 24px rgba(18, 38, 63, 0.12);

      &-notice--no-options {
        color: $lightText;
      }

      &-list {
        padding: 6px 0;

        border-width: 1px;
        border-style: solid;
        border-color: $border;
        border-radius: $borderRadius;
      }
    }

    &__placeholder {
      margin-right: 0;
      margin-left: 0;

      color: $lightText;
    }

    &__indicator {
      display: flex;

      padding-right: 12px;

      svg {
        transition: $transition;

        path {
          fill: $lightText;
        }
      }

      &-separator {
        display: none;
      }
    }
  }

  &--is-focused {
    border: none !important;
    box-shadow: none !important;
  }

  &--is-disabled {
    .select {
      &__control {
        background: transparent;
      }
    }
  }

  &__select-and-error-container {
    .select__control {
      border-color: $errorText !important;
    }
    position:relative;
    margin-bottom: 15px;
    flex: 1;
    .select {
      width: 100%;
    }
  }

  &__error-message {
    position: absolute;
    margin-top: 5px;
    margin-left: 0;
  }
}
