@import '../../css/guideline';

$proportion: 56.81818181818182%;

.article-card {
  &_preload {
    .article-card {
      &__tag {
        width: 25%;
        height: 20px;
        @extend %preloadStyle;
      }

      &__title {
        height: 40px;
        @extend %preloadStyle;
      }

      &__time {
        width: 40%;
        height: 20px;
        @extend %preloadStyle;
      }
    }
  }
}

.article-card {
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;

  &__time {
    @include media((margin-top: (all: 20px,
          mobileL: 16px)));
  }

  &__tag {
    margin-top: 12px;
    margin-right: 10px;
  }

  &__title {
    @include media((margin-top: (all: 12px,
          mobileL: 8px)));
  }

  &__image {
    color: transparent;
    width: 100%;
    position: relative;
    background-color: $preload;
    padding-bottom: $proportion;
    border-radius: $border-radius;
  }

  .typography.typography_type_text.typography_size_s {
    @include media((line-height: (mobileL: 16px),
        font-size: (mobileL: 12px)));
  }
}
