@import '../css/guideline.scss';

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  width: 40vw;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  div {
    width: 22vw;
    display: flex;
    flex-direction: column;
  }

  p {
    display: flex;

    &+p {
      margin-top: 10px;
    }
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.promo-widget {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;

  width: 100%;
  @extend %cardStyle;
  @include media((padding: (all: 48px,
        tabletM: 32px,
        mobileL: 20px),
      min-height: (all: 200px,
        mobileL: 0)));
}

.promo-widget__header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.promo-widget__header_service {
  padding-left: 5px;

  &--photo {
    color: #FF9933;
  }

  &--mail {
    color: #2E6BE6;
  }

  &--transfer {
    color: #3FCC6F;
  }

  &--press {
    color: #3D4B66;
  }

}

.promo-widget__header_icon {
  svg {
    width: 45px;
    height: 45px;
  }
}

.promo-widget__header-case {
  display: flex;
  align-items: center;
  justify-content: center;
}

.promo-widget__title {
  max-width: 495px;
  z-index: 0;
  padding-top: 18px;
  padding-bottom: 22px;
  @include media((max-width: (desktopM:77%,
        desktopS:77%,
        tabletM: 100%,
        mobileL: 100%),
    ));
}

.promo-widget__button {
  text-align: center;

  @include media((width: (all: auto,
        tabletM: auto,
        mobileL: 100%),
    ));
}


.promo-widget__background {
  position: absolute;
  right: 0;
  bottom: 0;
  @include media((display: (all: block,
        tabletM: none,
        mobileL: none),
    ));

}
