@import '../../../css/guideline.scss';

.page[name='index'] {

  .soul_banner {
    background-color: #ffe2ed;
    height: 150px !important;
    width: 100%;
    display: grid;

    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "text image logo";
    column-gap: 50px;
    align-items: center;
    padding: 0 99px;
    box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.05);
    border-radius: 6px;

    @include media((
      padding: ( desktopS: 0 41px, tabletS: 0 0 0 23px, mobileL: 0px),
    ));

    .item {
      display: grid;
      align-items: center;
      justify-content: center;
    }

    .logo-item {
      grid-area: logo;
      > img {
        @include media((
          width: (tabletM: 190px),
          height: (tabletM: 38px),
          margin-bottom: (tabletM: 15px)
        ));
      }
    }

    .text-item {
      grid-area: text;
      > img {
        @include media((
          width: (tabletM: 275px),
          height: (tabletM: 72px),
          margin-top: (tabletM: 15px)
        ));
      }
    }

    .image-item {
      grid-area: image;
      > img {
       width: 226px;
      }
      @include media((
        margin-left: (tabletM: -40px),
        display: (mobileL: none)
      ));
    }

    @include media((
      grid-template-columns: (tabletM: 1fr 1fr, mobileL: 1fr),
      grid-template-areas: (tabletM: "text image" "logo image", mobileL: "text logo image"),
      column-gap: (desktopS: 24px, tabletM: 49px,  tabletS: 0px),
      row-gap: (tabletM: 10px)
    ));
  }

  .sizovik_banner {
    position: relative;
    background-color: #E3FFEE;
    height: 150px !important;
    width: 100%;
    margin-bottom: 48px;
    display: grid;

    overflow: hidden;

    padding-top: 16px;
    padding-bottom: 12px;

    grid-template-areas: "text . logo";
    column-gap: 48px;
    row-gap: 16px;
    align-items: center;
    box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.05);
    border-radius: 6px;
    justify-items: center;

    @include media((
            padding-left: (all: 45px, tabletS: 32px, mobileL: 14px),
            padding-right: (all: 100px, desktopS: 42px, mobileL: 14px),
            padding-top: (all: 28px, tabletS: 24px, mobileL: 16px),
            padding-bottom: (all: 28px, tabletS: 28px, mobileL: 12px),

            grid-template-columns: (all: max-content 1fr max-content, tabletM: fit-content(100%) 1fr max-content, tabletS: 1f max-content 1f),
            grid-template-areas: (all: "text . logo", tabletS: ". text ." ". logo ."),
    ));

    .item {
      display: grid;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }

    .logo-item {
      grid-area: logo;

      width: fit-content;
      background-color: #FFFFFF;
      border-radius: 99px;
      box-shadow: 0 17.5px 52.5px 17.5px rgba(142, 144, 166, 0.16);

      @include media((
              padding: (all: 16px 24px, tabletS: 12.5px 18.7px),
      ));

      > img {
        object-fit: cover;
        @include media((
                width: (all: 170px, tabletM: 133px),
                height: (all: 48px, tabletM: 38px),
        ));
      }
    }

    .text-item {
      grid-area: text;

      color: #333;
      text-align: center;
      font-weight: 500;
      text-transform: uppercase;
      @include media((
              font-size: (all: 16px, tabletS: 14px)
      ));

      h2 {
        margin-bottom: 10px;

        color: #229A52;
        font-weight: 700;

        @include media((
                font-size: (all: 32px, desktopS: 30px, tabletS: 24px, mobileL: 20px)
        ));
      }
    }

    .bg-mobile {
      z-index: 0;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      @include media((
              display: (all: none, tabletS: inherit)
      ));

      & > img {
        width: 100%;
        object-fit: cover;
      }
    }

    .bg-desktop {
      z-index: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      right: calc((100% - 1080px) * 0.4);

      @include media((
              display: (all: none, desktopS: initial, tabletS: none)
      ));

      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .bg-desktop-large {
      z-index: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;

      @include media((
              display: (all: initial, desktopS: none)
      ));

      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .service_transfer {
    .service__description {
      max-width: none;
    }
  }

  &>div {
    @include media((padding-top: (all: 48px,
          tabletM: 32px,
          mobileL: 32px),
        padding-bottom: (all: 48px,
          tabletM: 32px,
          mobileL: 32px)));

    &.wrapper {
      position: relative;

      @include media((align-items: (mobileL: center),
          padding-top: (mobileL: 0px),
          margin-top: (mobileL: -48px)));
    }
  }

  .mobile-content {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 100%;
    margin-top: 32px;

    .collapse {
      &__footer {
        display: flex;
        justify-content: center;

        width: calc(100% + 32px);
        margin-top: 16px;
        margin-left: -16px;
        padding-top: 16px;

        border-top: 1px solid $lightBorder;
      }

      &+.collapse {
        margin-top: 16px;
      }

      h2 {
        font-size: 16px;
      }
    }

    .article-card {
      &__image {
        display: none;
      }
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: center;

    .button {
      min-width: 180px;

      .link {
        color: $white;
      }
    }
  }

  .services {
    align-items: center;

    &>.typography {
      @include media((margin-top: (tabletM: 32px,
            mobileL: 16px)));
    }
  }

  .institutions,
  .articles {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 100%;

    background: $white;
  }

  .articles .content {
    @include media((flex-direction: (tabletM: column)));

    &>div {
      &+div {
        @include media((margin-left: (all: 16px,
              tabletM: 0),
            margin-top: (all: 0,
              tabletM: 32px)));
      }
    }
  }

  .articles {
    @include media((padding-bottom: (all: 112px,
          tabletM: 60px)));
  }

  .institutions .content,
  .questions .content,
  .articles .content {
    display: flex;

    @include media((margin: (all: 48px 0,
          tabletM: 32px 0)));
  }

  .questions {
    .content {
      @include media((flex-direction: (tabletM: column)));

      &>div {
        flex: 1;

        &+div {
          @include media((margin-left: (all: 32px,
                tabletM: 0),
              margin-top: (all: 0,
                tabletM: 32px)));
        }

        &>div {
          .title {
            margin-bottom: 24px;
          }

          .typography_size_m {
            &+.typography_size_m {
              @include media((margin-top: (all: 24px,
                    tabletM: 16px)));
            }
          }

          &+div {
            @include media((margin-top: (all: 48px,
                  tabletS: 32px)));
          }
        }
      }
    }
  }
}
