@import '../../css/guideline.scss';

.tag {
  &_preload {
    width: 25%;
    height: 20px;
    @extend %preloadStyle;
  }
}

.tag {
  &.typography.typography_type_text.typography_size_s {
    @include media(
      (
        line-height: (
          mobileL: 16px
        ),
        font-size: (
          mobileL: 12px 
        )
      )
    );
  }
}
