// BASE =====================================
$white: #fff;
$black: #000;

$accent: #FF1A53;
$darkAccent: #ff3366;

$main: #295fcc;
$darkMain: #2454b3;

$second: #eff2f7;

// BACKGROUND =====================================
$darkBackground: #1f2d3d;
$lightBackground: #f8f8fb;

// TEXT =====================================
$text: #8492a6;
$darkText: #3D4B66;
$lightText: #aabacd;
$darkGreyText: #9299A2;

// BORDER =====================================
$border: #e0e6ed;
$darkBorder: #b8c0cc;
$lightBorder: #eff2f7;

// INFO =====================================
$errorText: #e61739;
$warningText: #cca352;
$successText: #47b347;
$errorBackground: #faf5f6;
$warningBackground: #faf8f5;
$successBackground: #f5faf5;

// SERVICES =====================================
$FSIN_PHOTO: #ff9933;
$FSIN_PHOTO_DARK: #f28b24;

$FSIN_QUEUE: #b336b3;
$FSIN_QUEUE_DARK: #b336b3;

$FSIN_MAIL: #2e6be6;
$FSIN_MAIL_DARK: #295fcc;

$FSIN_TRANSFER: #3fcc6f;
$FSIN_TRANSFER_DARK: #37b361;


$FSIN_VIDEO_TALKS: #9b51e0;

$FSIN_TV: #f23071;
$FSIN_TV_DARK: #e62e6b;

$FSIN_PRESS: #3D4B66;
;

// PRELOAD =====================================
$preload: rgba($border, 0.7);
