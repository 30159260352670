$m: 600px;
.status {
    display: flex;
    padding: 0;
    flex-direction: row;
    position: relative;
  
     &::before {
      content: '';
      position: absolute;
      top: 47%;
      transform: translateY(-50%);
      display: block;
      height: 2px;
      width: 100%;
      background-color: #C4C4C4;
  
      @media (min-width: $m) {
        top: 47%;
        margin: 0;
      }
    }
  
    &__li {
      display: flex;
      position: relative;
      list-style-type: none;
      height: 42px;
      width: auto;
  
      &:hover {
        cursor: pointer;
      }
  
      &:first-child {
        justify-content: flex-start;
  
        @media (min-width: $m) {
          .status__line {
            width: 80%;
            margin-left: 20%;
          }
        }
      }
  
      &:last-child {
        justify-content: flex-end;
  
        .status__item {
          margin-right: 0;
        }
      }
  
      @media (min-width: $m) {
        height: 62px;
        justify-content: flex-start;
      }
    }
  
    &__item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-right: 12px;
  
      @media (min-width: $m) {
        margin-right: 12px;
      }
    }
  
    &__mark {
      width: 100%;
      height: 100%;
      padding: 20%;
    }
  
    &__top {
      display: flex;
    }
  
    &__middle {
      display: flex;
      align-items: center;
  
      @media (min-width: $m) {
        margin: 4px 0;
      }
    }
  
    &__bottom {
      display: flex;
    }
  
    &__num {
      font-family: Roboto, sans-serif;
      margin: 0;
      color: #999999;
      font-weight: 400;
      font-size: 12px;
      width: 14px;
      text-align: center;
  
      @media (min-width: $m) {
        width: 24px;
      }
    }
  
    &__text {
      display: none;
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: #999999;
      margin: 0;
      margin-left: 8px;
  
      @media (min-width: $m) {
        display: none;
        margin-left: 0;
      }
    }
  
    &__sub-text {
      font-size: 10px;
      line-height: 10px;
      color: #999999;
  
      @media (min-width: $m) {
        font-size: 9px;
        width: auto;
      }
    }
  
    &__description {
      display: flex;
      flex-direction: column;
    }
  
    &__icon-container {
      z-index: 2;
      background-color: #ffffff;
    }
  
    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 99999px;
      box-sizing: border-box;
      background-color: white;
      border: 1px solid #C4C4C4;
      margin: 1px;
      width: 12px;
      height: 12px;
  
      @media (min-width: $m) {
        width: 22px;
        height: 22px;
      }
    }
  
    &__line {
      display: none;
      height: 2px;
      width: 100%;
      position: absolute;
      background-color: #47B247;
      top: 47%;
      transform: translateY(-50%);
  
      @media (min-width: $m) {
        top: 47%;
      }
    }
  
    &__line_half {
      width: 50%;
    }
  }
  
  .status_static {
  
    @media (min-width: $m) {
      margin: 0 -14px;
    }
  
    &::before {
      content: '';
      top: 47%;
  
      @media (min-width: $m) {
        top: 45%;
        width: 90%;
        margin: 0 5%;
      }
    }
  
    .status__li {
      justify-self: flex-start;
  
      &:first-child {
        justify-content: flex-start;
  
        @media (min-width: $m) {
          width: 17%;
        }
      }
  
      &:last-child {
        justify-content: flex-end;
  
        @media (min-width: $m) {
          width: 17%;
  
          .status__line {
            left: 0;
            width: 80%;
          }
        }
      }
  
      @media (min-width: $m) {
        width: 22%;
        justify-content: center;
  
        &:first-child {
           justify-content: flex-start;
        }
      }
  
      &.status_picked {
  
        &:last-child {
          justify-content: flex-start;
  
          @media (min-width: $m) {
            justify-content: flex-end;
          }
        }
      }
    }
  
    .status__num {
      @media (min-width: $m) {
        display: none;
      }
    }
  
    .status__text {
      @media (min-width: $m) {
        display: block;
      }
    }
  
    .status__sub-text {
      @media (min-width: $m) {
        text-align: center;
        width: 51px;
      }
    }
  
    .status__item {
      @media (min-width: $m) {
        margin-right: 0;
        align-items: center;
      }
    }
  
    .status__line {
      @media (min-width: $m) {
        top: 45%;
      }
    }
  
    .status_active {
      .status__line_half {
  
        @media (min-width: $m) {
          width: 100%;
        }
      }
  
      &:first-child {
        .status__line_half {
          width: 80%;
  
           @media (min-width: $m) {
            left: 20%;
            width: 50%;
          }
        }
      }
    }
  
    .status_picked.status_active {
      &:first-child {
        .status__line_half {
          width: 50%;
        }
      }
    }
  }
  
  .status_active {
    .status__icon {
      background-color: #47B247;
      border-color: #47B247;
    }
  
    .status__line {
      display: block;
    }
  
    .status__num {
      color: #000000;
    }
  
    .status__text {
      color: #000000;
    }
  
    .status__sub-text {
      color: #999999;
    }
  
    .status__line_half {
      width: 80%;
    }
  }
  
  .status_picked {
    flex-grow: 1;
  
    &:last-child {
      justify-content: flex-start;
    }
  
    .status__text {
      display: block;
    }
  
    &.status_active {
      .status__line_half {
        width: 50%;
  
        @media (min-width: $m) {
          width: 100%;
        }
      }
  
      @media (min-width: $m) {
        .status__num {
          margin-right: 8px;
        }
      }
    }
  }