@import '../../../css/guideline.scss';

.page {
  &__block {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include media(
      (
        flex-direction: (
          tablet: column
        )
      )
    );

    & > * + * {
      @include media(
        (
          margin-top: (
            tablet: 60px
          )
        )
      );
    }
  }
}
