@import '../../../css/guideline.scss';

$SIDEBAR_WIDTH: 352px;

.page[name='index'] {
  #sticky-container {
    display: flex;

    @include media(
      (
        flex-direction: (
          tabletM: column,
          mobileL: column
        )
      )
    );

    .sticky-outer-wrapper {
      @include media(
        (
          width: (
            all: $SIDEBAR_WIDTH,
            tabletM: 100%,
            mobileL: 100%
          ),
          min-width: (
            all: $SIDEBAR_WIDTH,
            tabletM: 100%,
            mobileL: 100%
          ),
          margin-left: (
            all: 32px,
            tabletM: 0
          ),
          margin-top: (
            tabletM: 32px
          )
        )
      );
    }
  }

  .page {
    &__content {
      width: 100%;

      & > div {
        @include media(
          (
            padding: (
              all: 40px 48px,
              mobileL: 32px,
              mobileS: 24px
            )
          )
        );
        @extend %cardStyle;

        .form__group {
          margin-top: 24px;
        }

        & + div {
          margin-top: 22px;
        }
      }
    }

    &__sidebar {
      &-form {
        display: flex;
        flex-direction: column;
        .mb-10{
          margin-bottom:5px;
          margin-top:5px;
        }
        .tax-row{
          margin-top:7px;
          padding-top:7px;
          display:flex;
          flex-direction: row;
          justify-content: space-between;
          border-top: 1px solid #ddd;
          &.last{
            border-bottom: 1px solid #ddd;
            margin-bottom:15px;
            padding-bottom:7px;
          }
        }

        @include media(
          (
            padding: (
              all: 40px 48px,
              mobileL: 32px,
              mobileS: 24px
            )
          )
        );
        @extend %cardStyle;

        .info {
          margin-top: 16px;
        }

        & > p.typography {
          margin: 0 auto;
          margin-top: 24px;

          text-align: center;

          @include media(
            (
              max-width: (
                all: 200px,
                tabletM: 270px
              )
            )
          );

          a {
            display: inline;
          }
        }

        &-calculate {
          display: flex;
          flex-direction: column;

          margin-top: 24px;
          margin-bottom: 16px;

          & > * {
            display: flex;
            align-items: center;
            justify-content: space-between;

            & + * {
              margin-top: 4px;
            }

            & > * {
              display: flex;
              align-items: center;
            }
            .disabled input,
            input:disabled {
              max-width: 100px;

              border-color: transparent;
            }

            .value,
            input {
              display: flex;
              align-items: center;

              max-width: 100px;
              height: 40px;
              margin-right: 8px;
              padding-left: 0px;

              text-align: right;
            }
            .value {
              margin-right: 20px;

              span {
                padding-top: 3px;

                cursor: pointer;

                border-bottom: 1px dashed #8492a6;
              }
            }
          }
        }
      }

      &-services {
        margin-top: 24px;

        & > * + * {
          margin-top: 24px;
        }
      }
    }
  }

  .form {
    display: flex;

    width: 100%;
    flex: 1 0;
    .fixed-sidebar{
      position:relative;
      margin-left:32px;
      @include media(
        (
          width: (
            all: $SIDEBAR_WIDTH,
            tabletM: 100%,
            mobileL: 100%
          ),
          min-width: (
            all: $SIDEBAR_WIDTH,
            tabletM: 100%,
            mobileL: 100%
          ),
          margin-left: (
            all: 32px,
            tabletM: 0
          ),
          margin-top: (
            tabletM: 32px
          )
        )
      );
      .page__sidebar{
        position:sticky;
        top:0px;
        left:0;
      }
    }
    & > * + * {
      margin-top: 0;
    }

    .form__group {
      @include media(
        (
          flex-direction: (
            tabletS: column !important
          )
        )
      );

      & > * + * {
        @include media(
          (
            margin-left: (
              tabletS: 0
            ),
            margin-top: (
              tabletS: 16px
            )
          )
        );
      }

      & + .form__group {
        @include media(
          (
            margin-top: (
              tabletS: 16px
            )
          )
        );
      }
    }
  }
}
