@import '../../css/guideline.scss';

$buttonSize: 64px;
$iconMargin: 6px;

.search {
  &_with-search,
  &_focusible {
    .search__form {
      & > div {
        border-color: $darkBorder;
        background: $white;

        input {
          color: $darkText;

          caret-color: $darkText;
        }
      }
    }
  }

  &_with-search {
    .search__form {
      button {
        &:hover {
          & > div {
            background: lighten($main, 5%);
          }
        }

        & > div {
          background: $main;
          svg path {
            fill: $white;
          }
        }
      }
    }
  }

  &__form {
    position: relative;

    display: flex;

    height: 64px;

    & > * + * {
      margin-top: 0;
    }

    & > div {
      display: flex;
      align-items: center;

      width: 100%;
      height: 100%;
      padding-right: $buttonSize;
      padding-left: 24px;

      transition: $transition;

      color: $text;
      border: 1px solid $lightBorder;
      border-radius: 100px;
      background: $white;
      box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.03);
    }

    input {
      padding: 0;

      border: none;

      font-size: 16px;
    }

    label {
      p{
        width: 0;
        overflow: hidden;
      }
    }

    button {
      position: absolute;
      top: 0;
      right: 0;

      width: $buttonSize;
      min-width: $buttonSize;
      height: $buttonSize;
      padding: $iconMargin;

      border: none;
      outline: none;
      background: transparent;

      & > div {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;

        cursor: pointer;
        transition: $transition;

        border: none;
        border-radius: 100%;
        outline: none;
        background: transparent;

        svg path {
          fill: $darkBorder;
        }
      }
    }

    &_active {
      input {
        color: $black;
        background: $white;
      }
    }
  }

  &__examples {
    display: flex;

    margin-top: 12px;
    padding-left: 24px;

    & > .typography {
      display: flex;
      align-items: center;

      width: 240px;
      height: 40px;
      padding: 0 8px;

      border-radius: $border-radius;
      background: $white;

      @include media(
        (
          width: (
            all: 240px,
            tabletM: auto
          )
        )
      );

      & + .typography {
        margin-left: 12px;
      }
    }
  }

  &_menu {
    margin-top: 28px;

    &.search {
      &_with-search,
      &_focusible {
        .search__form {
          & > div {
            border-color: transparent;
            background: $white;

            input {
              color: $darkText;

              caret-color: $darkText;
            }
          }

          button {
            &:hover {
              & > div {
                background: lighten($accent, 5%);
              }
            }

            & > div {
              background: $accent;
              .svg-image svg {
                fill: $white;
              }
            }
          }
        }
      }
    }
    
    .search {
      &__form {
        & > div {
          border-color: transparent;
          background: rgba(255, 255, 255, 0.1);
          box-shadow: none;

          input {
            color: rgba(255, 255, 255, 0.75);
          }
        }

        button {
          padding: 0;

          &:hover {
            & > div {
              background: $darkAccent;
            }
          }

          & > div {
            background: $accent;

            svg path {
              fill: $white;
            }
          }
        }
      }
    }
  }


  &_menu {
    .cross {
      display: none;
    }
  }

  .cross {
    path {
      transition: $transition;
    }

    &:hover {
      path {
        fill: $darkText;
      }
    }
  }
}
