@import '../../css/guideline.scss';

.link {
  display: flex;
  align-items: center;

  cursor: pointer;
  transition: $transition;

  &_active {
  }

  &_disabled {
  }

  &_blue {
    color: $main;
  }

  &_green {
    color: $FSIN_TRANSFER;
  }
}
