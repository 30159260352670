@import '../../css/guideline.scss';

.promo-widget {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;

  width: 100%;

  .typography_promo, .typography_description{
    margin-bottom:20px;
    margin-top:-20px;
    @media(max-width: 600px){
      margin-top:-10px;
    }
  }
  @extend %cardStyle;
  @media(max-width:600px){
    text-align:center;
  }
  @include media((padding: (all: 48px,
        tabletM: 32px,
        mobileL: 20px),
      min-height: (all: 200px,
        mobileL: 0)));
}

.promo-widget__header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.promo-widget__header_service {
  padding-left: 5px;

  &--photo {
    color: #FF9933;
  }

  &--mail {
    color: #2E6BE6;
  }

  &--transfer {
    color: #3FCC6F;
  }

  &--press {
    color: #3D4B66;
  }

}

.promo-widget__header_icon {
  svg {
    width: 45px;
    height: 45px;
  }
}

.promo-widget__header-case {
  display: flex;
  align-items: center;
  justify-content: center;
}

.promo-widget__title {
  z-index: 0;
  padding-top: 18px;
  padding-bottom: 22px;
}

.promo-widget__button {
  @include media((width: (all: auto,
        tabletM: auto,
        mobileL: 100%),
    ));
}


.promo-widget__background {
  position: absolute;
  right: 0;
  bottom: 0;
  @include media((display: (all: block,
        tabletM: none,
        mobileL: none),
    ));
}

.promo-widget--press {

  .promo-widget__title {
    max-width: none;
  }

  .typography_promo {
    margin-bottom: 10px;
  }

  .typography_description {
    color: #3D4B66;
  }

  span {
    color: #32C8B4;
  }

  a {
    background-color: #32C8B4;
  }

  svg {
    width: 32px;
    height: 32px;
    margin-right: 7px;
  }
}

