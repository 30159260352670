@import '../../css/guideline.scss';

.section-title {
  display: flex;
  align-items: center;
  word-break: break-word;
  justify-content: space-between;

  .typography {
    display: flex;
    align-items: center;

    & > .link:first-child {
      margin-right: 24px;
    }

    & > span:first-child {
      margin-right: 6px;
    }
  }

  &__additional {
    display: flex;
    align-items: center;
  }
}
