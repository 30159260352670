.FormResult {
  margin-bottom: 48px;
  border: 1px solid #eff2f7;
  box-sizing: border-box;
  box-shadow: 0 12px 24px rgba(18, 38, 63, 0.03);
  border-radius: 6px;
  background: #fff;

  &__header {
    display: flex;
    padding: 40px 48px;
    background: linear-gradient(90deg, #2c5e54 0%, #3dc476 100%);
    border-radius: 6px 6px 0 0;
    font-size: 16px;
    line-height: 20px;
    color: #b9dbca;
    @media screen and (max-width: 768px) {
      font-size: 14px;
      flex-direction: column;
      align-items: center;
      padding: 32px 20px 24px;
    }
    h4,
    p {
      margin: 0;
    }
    h4 {
      font-size: 20px;
      line-height: 24px;
      color: #fff;
      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
    }
  }

  &__header-text {
    padding-top: 4px;
    margin-left: 24px;
    @media screen and (max-width: 768px) {
      padding-top: 0;
      margin-top: 20px;
      margin-left: 0;
      text-align: center;
    }
  }

  &__body {
    width: 100%;
    padding: 32px 48px 48px;
    box-sizing: border-box;
    @media(max-width: 765px) {
      padding: 16px;
    }
  }

  &__title {
    margin: 0;
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #3c4858;
    cursor: default;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 16px;
    line-height: 24px;
    @media screen and (max-width: 768px) {
      font-size: 14px;
      line-height: 20px;
    }

    & > li {
      position: relative;
      padding-left: 24px;
      margin: 8px 0;
      &::after {
        content: "\2022";
        font-size: 20px;
        color: #c3cad9;
        font-weight: bold;
        position: absolute;
        left:0;
        top:0;
      }
    }
  }

  &__warn {
    margin: 24px 0;
    padding: 20px 24px;
    background: rgba(251, 205, 115, 0.3);
    border-radius: 8px;
    font-size: 14px;
    line-height: 18px;
    color: #3d4b66;
  }
}

.queue__modal_overlay{
  z-index:110;
  background-color: rgba(0,0,0, 0.5);
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  &> div{
    display: block;
    width:100%;
    position:relative;
    height:100%;
    max-width:1128px;
    margin:0 auto;
    background-color: #fff;
    padding:70px 30px 70px 50px;
    overflow-y: auto;
    .content{
        overflow-y:auto;
        height:100%;
        p{
            margin-top:20px;
            margin-bottom:20px;
            font-size:14px;
            line-height: 24px;
            @media(max-width:600px){
                font-size:15px;
            }
        }
        ol{
            margin-left:25px;
            li{
                margin-top:10px;
                line-height:24px;
            }
        }
    }
    .title-xl{
        font-size: 17px;
        line-height: 27px;
        text-align: center;
        width:75%;
        margin:0 auto;
        @media(max-width:768px){
            width:100%;
        }
    }
    @media(max-width:768px){
        height:100%;
        width:100%;
        box-shadow: inset 0px 10px 20px #ddd;
        padding: 0 30px;
        padding-top:60px;
    } 
    &__dark{
        display:none;
        position:fixed;
        top:0;
        right:0;
        left:0;
        bottom: 0;
        background-color: rgba(0,0,0,0.3);
        z-index: 101;
        padding:80px;
        .close-click{
            width:100%;
            height:100%;
            position:absolute;
            top:0;
            left:0;right:0;
            bottom:0;
        }
        &.show{
            display:block;
        }
        .hide{
            display:none;
        }
        @media(max-width:1280px){
            padding: 80px 36px;
        }
        @media(max-width: 1024px){
            padding:80px 48px;
        }
        @media(max-width:768px){
            padding: 0;
            padding-top:72px;
            z-index: 99;
        }
        @media(max-width:600px){
            padding-top:56px;
        }
    }
    &__close{
        position:absolute;
        right:25px;
        top:20px;
        background-color: #ccc;
        cursor:pointer;
        path{
            fill: #fff;
        }
    }
  }
  
}

.link_queue{
  color: blue;
  padding-left:5px;
  padding-right:5px;
  cursor:pointer;
}