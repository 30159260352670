@import '../../../css/guideline.scss';

.page[name='articles'] {
  .page_preload {
    .grid {
      margin-top: 32px;
    }

    .page {
      &__title {
        width: 300px;
        max-width: 80%;
        height: 40px;

        @extend %preloadStyle;
      }

      &__tabs {
        height: 28px;

        @extend %preloadStyle;
      }
    }
  }
}

.page[name='articles'] {
  .page__title {
    @include media(
      (
        margin-bottom: (
          all: 48px,
          mobileL: 28px
        )
      )
    );

    .typography {
      align-items: flex-end;
    }
  }
}
