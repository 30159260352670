@import '../../css/guideline.scss';

.modal[data-modal='text-error'] {
  align-items: flex-end;

  .cross-container {
    width: 14px;
    height: 14px;

    @include media(
      (
        right: (
          all: $wrapperPaddingAll,
          desktopS: $wrapperPaddingDesktopS,
          tabletM: $wrapperPaddingTabletM,
          tabletS: $wrapperPaddingTabletS,
          mobileL: $wrapperPaddingMobileL,
          mobileM: $wrapperPaddingMobileM,
          mobileS: $wrapperPaddingMobileS
        )
      )
    );

    svg {
      width: 100%;
      height: 100%;
    }

    path {
      stroke: $darkBorder;
    }
  }

  &.modal_visible {
    .scroll-container__wrapper {
      opacity: 1;
    }
  }

  .scroll-container {
    width: 100%;

    &__wrapper {
      align-items: flex-end;

      width: 100%;
      height: auto;
      padding: 0;

      transition: all 500ms ease;

      opacity: 0;
      &-content {
        align-items: center;

        width: 100%;
        max-width: none;
        padding: 64px 0;

        transition: none;
        transform: none;

        opacity: 1;
        .wrapper {
          margin: 0 auto;
        }

        .section-title {
          margin-bottom: 24px;
        }

        textarea {
          min-height: 144px;
        }

        .buttons {
          display: flex;

          margin-top: 32px;

          .button + .button {
            margin-left: 24px;
          }
        }
      }
    }
  }

  .modal__fade {
    background: rgba(0, 0, 0, 0.1);
  }
}
