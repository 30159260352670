

.modal_queue{
    display: block;
    width:100%;
    position:relative;
    height:100%;
    max-width:1128px;
    margin:0 auto;
    border-radius: 20px;
    background-color: #fff;
    padding:70px 30px 70px 50px;
    box-shadow: 0px 0px 20px 1px #777;
    &__content{
        
        overflow-y:auto;
        height:100%;

        &::-webkit-scrollbar {
            width: 1em;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            
          }
          &::-webkit-scrollbar-thumb {
            background-color: #ccc;
            cursor:pointer;
            border-radius: 10px;
          }
        p{
            margin-top:20px;
            margin-bottom:20px;
            font-size:14px;
            line-height: 24px;
            @media(max-width:600px){
                font-size:15px;
            }
        }
        ol{
            margin-left:25px;
            list-style:decimal !important;
            li{
                margin-top:10px;
                line-height:24px;
            }
        }
    }
    .title-xl{
        font-size: 17px;
        line-height: 27px;
        text-align: center;
        width:75%;
        margin:0 auto;
        margin-bottom: 15px;
        @media(max-width:768px){
            width:100%;
        }
    }
    @media(max-width:768px){
        height:100%;
        width:100%;
        box-shadow: inset 0px 10px 20px #ddd;
        padding: 0 30px;
        padding-top:80px;
        border-radius:0;
    } 
    &__dark{
        display:none;
        position:fixed;
        top:0;
        right:0;
        left:0;
        bottom: 0;
        background-color: rgba(0,0,0,0.3);
        z-index: 101;
        padding:80px;
        justify-content: center;
        align-items: center;
        .close-click{
            width:100%;
            height:100%;
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
        }
        &.show{
            display:flex;
        }
        .hide{
            display:none;
        }
        @media(max-width:1280px){
            padding: 80px 36px;
        }
        @media(max-width: 1024px){
            padding:80px 48px;
        }
        @media(max-width:768px){
            padding: 0;
            z-index: 102 !important;
        }
    }
    &__close{
        position:absolute;
        right:20px;
        top:20px;
        border-radius: 20px;
        padding:5px;
        background-color: #f5f5f5;
        transition: background-color 0.2s;
        cursor:pointer;
        &:hover{
            background-color: #e0e0e0;
        }
        path{
            fill: #fff;
        }
    }
}


b{
    font-weight: bold !important;
    font-size: 18px !important; 
}