@import '../../../css/guideline.scss';

.comments {
  &__form {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media(
      (
        margin-top: (
          all: 25px,
          mobileL: 0
        ),
        margin-bottom:(
          all: 0,
          mobileL: 40px
        )
      )
    );

  }

  &__send {
    cursor: pointer;
    border: none;
    background: none;
    position: absolute;
    padding: 15px 0;
    padding-right: 15px;
    right: 0;
    & .svg-image{
      @include media(
        (
          display: (
            all: block,
            mobileL: none
          )
        )
      );
    }
    & .send_text{
      color: #fff;
      @include media(
        (
          display: (
            all: none,
            mobileL: block
          )
        )
      );
    }

    @include media(
      (
        position: (
          all: absolute,
          mobileL: static
        ),
        right: (
          all: 0,
          mobileL: auto
        ),
        background-color: (
          all: none,
          mobileL: #295FCC
        ),
        padding:(
          all: 5px 15px 0 0,
          mobileL: 12px 24px
        ),
        border-radius:(
          all: none,
          mobileL: 4px
        ),
        margin-top:(
          all: none,
          mobileL: 24px
        )

      )
    );
    &.hidden_mobile {
      @include media(
        (
          display: (
            all: block ,
            mobileL: none 
          )
        )
      )
    }
  }

  &__emoji {
    background: none;
    border: none;
    position: absolute;
    right: 34px;
    width: 48px;
    height: 48px;
    @include media(
      (
        display: (
          all: block,
          mobileL: none
        )
      )
    );
  }

  &__auth_message {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: -20px;
    right: 0;
  }
}

.emoji_picker {
  display: block;
  z-index: 1;
  position: absolute;
  right: 0px;
  top: 50px;

  &.hidden {
    display: none;
  }

  section {
    display: block;
  }

  ul {
    display: block;
    flex-direction: row;
  }

  li {
    &:before {
      content: none;
    }
  }
}