@import '../../css/guideline.scss';
.head {
  position: relative;

  overflow: hidden;

  width: 100%;

  &.head {
    &_service {
      &_pochta,
      &_queue,
      &_foto,
      &_perevod {
        background: #40806a;
        background: linear-gradient(90deg, #194040 0%, #40806a 99.74%);

        @include media(
          (
            padding: (
              tabletS: $tabletHeaderHeight + 48px 0 48px,
              mobileL: $mobileHeaderHeight + 32px 0 32px
            ),
            margin-top: (
              tabletS: -$tabletHeaderHeight,
              mobileL: -$mobileHeaderHeight
            )
          )
        );

        .button {
          width: 100%;
          max-width: 220px;

          color: $white;
          border-color: $text;

          @include media(
            (
              margin-top: (
                all: 24px,
                tabletS: 0
              )
            )
          );
        }

        .wrapper {
          @include media(
            (
              flex-direction: (
                tabletS: column
              ),
              padding-bottom: (
                mobileL: 48px
              )
            )
          );

          .typography {
            @include media(
              (
                text-align: (
                  tabletS: center
                )
              )
            );

            &.opacity_full {
              opacity: 1;
            }

            &_type_heading {
              @include media(
                (
                  margin-bottom: (
                    tabletS: 32px
                  )
                )
              );
            }

            &_type_text {
              max-width: 724px;

              opacity: 0.5;

              @include media(
                (
                  order: (
                    tabletS: 4
                  )
                )
              );
            }

            &_promo{
              opacity:1;
              color:#84e219;
              margin-bottom: 10px;
            }
          }

          & > .svg-image {
            max-width: 100%;
            height: auto;

            @include media(
              (
                margin-left: (
                  all: 12px,
                  tabletS: 0
                )
              )
            );

            svg {
              height: auto;
            }
          }

          & > div {
            display: flex;
            flex-direction: column;

            width: 100%;

            @include media(
              (
                justify-content: (
                  mobileL: center
                ),
                align-items: (
                  mobileL: center
                ),
                padding: (
                  all: 72px 0,
                  tabletS: 48px 0,
                  mobileL: 0 0 24px
                )
              )
            );
          }
        }
      }

      &_pochta {
        background: #404080;
        background: linear-gradient(90deg, #1f2e4d 0%, #404080 99.74%);
      }

      &_queue{
        background: #0189d5;
        background: linear-gradient(90deg, #451F4D 0%, #6A4080 100%);
        .button{
          max-width:270px;
          border-color: #fff;
          line-height:24px;
          font-size:15px;
          padding: 12px 24px !important;
        }
        .svg-image{
          @media(max-width:768px){
            margin-bottom:16px;
          }
        }
      }

      &_foto{
        background: #192640;
        background: linear-gradient(90deg, #192640 0%, #2E2966 99.74%);
        .svg-image{
          @media(max-width:768px){
            margin-bottom:16px;
          }
        }
      }

      &_main {
        @extend %indexHeadAnimation;
        @include media(
          (
            padding-top: (
              all: $headerHeight + 48px,
              tabletS: $tabletHeaderHeight + 48px,
              mobileL: $mobileHeaderHeight + 32px
            ),
            padding-bottom: (
              all: 48px,
              mobileL: 96px
            ),
            margin-top: (
              all: -$headerHeight,
              tabletS: -$tabletHeaderHeight,
              mobileL: -$mobileHeaderHeight
            )
          )
        );
      }
    }
  }

  .wrapper {
    position: relative;
    z-index: 10;

    margin: 0 auto;

    @include media(
      (
        align-items: (
          mobileL: center
        )
      )
    );

    .typography_type_heading {
      max-width: 700px;
      margin-bottom: 12px;

      @include media(
        (
          margin-bottom: (
            all: 12px,
            mobileL: 48px
          ),
          font-size: (
            mobileL: 20px
          ),
          text-align: (
            mobileL: center
          ),
          line-height: (
            mobileL: 24px
          )
        )
      );
    }

    .typography_type_text {
      max-width: 760px;
    }
  }
}
