@import '../../../css/guideline.scss';

.page[name='orders-foto'] {
    li {
        margin-top: 0px;
        list-style: none !important;
        &:before {
            content: none !important;
            display: none !important;
        }
    }
    .page {
        &__row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            &.last {
                margin-top: 36px;
            }
        }
        &__status-bar {
            margin-bottom: 36px;
            margin-top: 36px;
        }
        &__center {
            align-items: center;
            display: flex;
            flex-direction: column;
            & > * {
                margin-bottom: 34px;
            }
            & > *:first-child {
                margin-bottom: 64px;
            }
        }
        .button {
            width: 200px;
        }

        &__box {
            margin-bottom: 24px;
            &:last-child {
                margin-bottom: 0;
            }

            > * {
                margin-bottom: 7px;
            }
        }
        &__content {
            width: 640px;
            margin-left: auto;
            margin-right: auto;

            & > div {
                @include media(
                    (
                        padding: (
                            all: 40px 48px,
                            mobileL: 32px,
                            mobileS: 24px
                        )
                    )
                );
                @extend %cardStyle;

                .form__group {
                    margin-top: 24px;
                }

                & + div {
                    margin-top: 22px;
                }
            }
        }
    }
}
