@import '../../../css/guideline.scss';

$SIDEBAR_WIDTH: 352px;

.page[name='index-foto'] {
  #sticky-container {
    display: flex;
    .disabled{
      position:relative;
      &::before{
        position:absolute;
        content: '';
        top:0;
        left:0;
        bottom:0;
        right:0;
        background-color:rgba(255,255,255, 0.5);
        overflow:hidden;
        z-index:20;
      }
    }
    @include media(
      (
        flex-direction: (
          tabletM: column,
          mobileL: column
        )
      )
    );
  }
  .page {
    &__content {
      width: 100%;
      & > div {
        @include media(
          (
            padding: (
              all: 40px 48px,
              mobileL: 32px,
              mobileS: 24px
            )
          )
        );
        @extend %cardStyle;

        .form__group {
          margin-top: 24px;
        }

        & + div {
          margin-top: 22px;
        }
      }
    }

    &__sidebar {
      &-tariff{
        display:flex;
        flex-direction:row;
        justify-content: space-between;
        border-bottom: 1px solid #cecece;
        padding-bottom:10px;
        margin-bottom:10px;
        &:last-child{
          margin-bottom: 0;
        }
        &-active{
          & *{
            color: auto;
          }
        }
        &-not-active{
          & *{
            color: #ddd;
          }
        }

        &-total-sum{
          display:flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom:10px !important;
          margin-top:10px;
        }
        &-cost{
          display:flex;
          flex-direction: row;
          align-items: center;
          :nth-child(2){
            margin-left:10px;
          }
          .line-trough{
            text-decoration: line-through;
          }
        }
      }
      &-form {
        display: flex;
        flex-direction: column;

        &> div {
          margin-bottom:20px;
        }

        @include media(
          (
            padding: (
              all: 40px 48px,
              mobileL: 32px,
              mobileS: 24px
            )
          )
        );
        @extend %cardStyle;

        & > p.typography {
          margin: 0 auto;
          margin-top: 24px;

          text-align: center;

          @include media(
            (
              max-width: (
                all: 200px,
                tabletM: 270px
              )
            )
          );

          a {
            display: inline;
          }
        }

        &-calculate {
          display: flex;
          flex-direction: column;

          margin-top: 24px;
          margin-bottom: 16px;

          span {
            span {
              color: $black;
            }
          }

          & > div + div {
            margin-top: 12px;
          }

          .total {
            align-items: center;

            margin-top: 24px;
          }

          & > * {
            display: flex;
            align-items: center;
            justify-content: space-between;

            & + * {
              margin-top: 4px;
            }

            & > * {
              display: flex;
              align-items: center;
            }

            input {
              max-width: 90px;
              margin-right: 8px;
              padding-right: 10px;
              padding-left: 0px;

              text-align: right;

              &:disabled {
                border-color: transparent;
              }
            }
          }
        }
      }

      &-services {
        margin-top: 24px;

        & > * + * {
          margin-top: 24px;
        }
      }
    }
  }

  .title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .row{
      display:flex;
      flex-direction: row;
      align-items: center;
      & > * + * {
        margin-left: 5px;
      }
    }
    & + * {
      margin-top: 24px;
    }
  }

  .form {
    display: flex;
    width: 100%;
    flex: 1 0;
    .fixed-sidebar{
      position:relative;
      margin-left:32px;
      @include media(
        (
          width: (
            all: $SIDEBAR_WIDTH,
            tabletM: 100%,
            mobileL: 100%
          ),
          min-width: (
            all: $SIDEBAR_WIDTH,
            tabletM: 100%,
            mobileL: 100%
          ),
          margin-left: (
            all: 32px,
            tabletM: 0
          ),
          margin-top: (
            tabletM: 32px
          )
        )
      );
      .page__sidebar{
        position:sticky;
        top:0px;
        left:0;
      }
    }
    & > * + * {
      margin-top: 0;
    }

    .form__group {
      @include media(
        (
          flex-direction: (
            tabletS: column !important
          )
        )
      );

      & > * + * {
        @include media(
          (
            margin-left: (
              tabletS: 0
            ),
            margin-top: (
              tabletS: 16px
            )
          )
        );
      }

      & + .form__group {
        @include media(
          (
            margin-top: (
              tabletS: 16px
            )
          )
        );
      }
    }
  }
}
