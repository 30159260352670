@import '../../../css/guideline.scss';

.page[name='error'] {
  .wrapper {
    flex-grow: 1;

    @include media(
      (
        flex-direction: (
          tabletM: column,
          mobileL: column
        ),
        padding-top: (
          tabletM: 32px,
          mobileL: 16px
        ),
        padding-bottom: (
          tabletM: 32px,
          mobileL: 16px
        ),
        justify-content: (
          tabletM: flex-start,
        )
      )
    );

    & > :last-child {
      @include media(
        (
          margin-left: (
            all: 60px,
            tabletM: 0,
            mobileL: 0
          ),
          width: (
            all: 100%,
            mobileL: 100%
          ),
          max-width: (
            all: 544px
          )
        )
      );

      svg {
        width: 100%;
        height: auto;
      }
    }

    & > div:first-child {
      @include media(
        (
          padding-bottom: (
            all: 16%,
            tabletM: 32px,
            mobileL: 24px
          ),
          min-width: (
            all: 400px,
            tabletM: 100%
          )
        )
      );

      .section-title {
        @include media(
          (
            margin-bottom: (
              all: 24px,
              mobileL: 12px
            )
          )
        );
      }

      .buttons {
        display: flex;

        @include media(
          (
            flex-direction: (
              mobileL: column
            ),
            margin-top: (
              all: 48px,
              tabletM: 24px,
              mobileL: 16px
            )
          )
        );

        & > .button:first-child {
          @include media(
            (
              margin-right: (
                all: 32px,
                tabletM: 16px,
                mobileL: 0
              ),
              margin-bottom: (
                mobileL: 16px
              )
            )
          );
        }
      }
    }
  }
}
