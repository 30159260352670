@import '../../css/guideline.scss';

.wrapper {
  display: flex;
  flex-direction: column;

  width: 100%;

  @include media(
    (
      padding: (
        all: 0 $wrapperPaddingAll,
        desktopS: 0 $wrapperPaddingDesktopS,
        tabletM: 0 $wrapperPaddingTabletM,
        tabletS: 0 $wrapperPaddingTabletS,
        mobileL: 0 $wrapperPaddingMobileL,
        mobileM: 0 $wrapperPaddingMobileM,
        mobileS: 0 $wrapperPaddingMobileS
      ),
      max-width: (
        all: $maxWidthWrapper + $wrapperPaddingAll * 2,
        desktopS: $maxWidthWrapper + $wrapperPaddingDesktopS * 2,
        tabletM: $maxWidthWrapper + $wrapperPaddingTabletM * 2,
        tabletS: $maxWidthWrapper + $wrapperPaddingTabletS * 2,
        mobileL: $maxWidthWrapper + $wrapperPaddingMobileL * 2,
        mobileM: $maxWidthWrapper + $wrapperPaddingMobileM * 2,
        mobileS: $maxWidthWrapper + $wrapperPaddingMobileS * 2
      )
    )
  );

  &.wrapper_withTabs {
    overflow-x: hidden;
  }

  &_page {
    @include media(
      (
        padding-top: (
          all: 48px,
          tabletM: 32px,
          mobileL: 16px
        ),
        padding-bottom: (
          all: 48px,
          tabletM: 32px,
          mobileL: 32px
        )
      )
    );
  }

  &_row {
    flex-direction: row;
  }

  &_center {
    align-items: center;
    justify-content: space-between;
  }
}
