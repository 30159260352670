@import './reset';
@import './media';
@import './guideline';

* {
  box-sizing: border-box;
}

::-webkit-input-placeholder {
  color: $border;
}
::-moz-placeholder {
  color: $border;
}
:-ms-input-placeholder {
  color: $border;
}
:-moz-placeholder {
  color: $border;
}

html, body {
  height: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: normal;

  -webkit-tap-highlight-color: transparent;
  // @include media((-webkit-tap-highlight-color: (tabletM: transparent)));

  ul {
    display: flex;
    flex-direction: column;

    & > * {
      position: relative;
      display: list-item;
      @include media(
        (
          padding-left: (
            all: 24px,
            mobileL: 20px
          ),
        )
      );

      &:before {
        position: absolute;

        @include media(
          (
            top: (
              all: 9px,
              mobileL: 7px
            )
          )
        );
        left: 0;

        width: 6px;
        min-width: 6px;
        height: 6px;

        content: '';

        border-radius: 100%;
        background: $text;
      }

      & + * {
        margin-top: 12px;
      }
    }
  }

  #app {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    min-height: 100%;

    .mobile_hide {
      @include media(
        (
          display: (
            mobileL: none
          )
        )
      );
    }

    .tablet_hide {
      @include media(
        (
          display: (
            tabletM: none
          )
        )
      );
    }

    .tablet_S_hide {
      @include media(
        (
          display: (
            tabletS: none
          )
        )
      );
    }

    .tablet_M_hide {
      @include media(
          (
            display: (
              tabletM: none
            )
          )
      );
    }

    .mobile_show {
      @include media(
        (
          display: (
            all: none,
            mobileL: flex
          )
        )
      );
    }

    .tablet_show {
      @include media(
        (
          display: (
            all: none,
            tabletM: flex
          )
        )
      );
    }

    .tablet_S_show {
      @include media(
        (
          display: (
            all: none,
            tabletS: flex
          )
        )
      );
    }

    .tablet_M_show {
      @include media(
          (
            display: (
              all: none,
              tabletM: flex
            )
          )
      );
    }

    h1.placeholder {
      position: absolute;
      top: 50%;
      left: 0;

      width: 100%;

      transform: translateY(-50%);
      text-align: center;

      opacity: 0.1;

      font-size: 140px !important;

      @include media(
        (
          font-size: (
            all: 140px !important,
            mobileL: 30px !important
          )
        )
      );
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;

  background-color: rgba(0, 0, 0, 0.001);
  box-shadow: 0 0 0px 0 #fff inset;
}
