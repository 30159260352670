@import '../../css/guideline';

$INSTITUTION_RIGHT: 32px;
$INSTITUTION_BOTTOM: 32px;

$INSTITUTION_MOBILE_RIGHT: 0px;
$INSTITUTION_MOBILE_BOTTOM: 16px;

$ARTICLE_RIGHT: 32px;
$ARTICLE_BOTTOM: 40px;

$ARTICLE_MOBILE_RIGHT: 0px;
$ARTICLE_MOBILE_BOTTOM: 16px;

.grid {
  display: flex;
  flex-wrap: wrap;

  width: 100%;

  &__item {
    flex-grow: 1;

    &_empty {
      height: 0 !important;
      margin-top: 0 !important;
    }
  }

  &_articles {
    @include media(
      (
        margin-top: (
          all: -$ARTICLE_BOTTOM,
          mobileL: -$ARTICLE_MOBILE_BOTTOM
        ),
        margin-right: (
          all: -$ARTICLE_RIGHT,
          mobileL: -$ARTICLE_MOBILE_RIGHT
        ),
        width: (
          all: calc(100% + #{$ARTICLE_RIGHT}),
          mobileL: calc(100% + #{$ARTICLE_MOBILE_RIGHT})
        )
      )
    );

    .grid {
      &__item {
        @include media(
          (
            width: (
              all: 352px,
              mobileL: 100%
            ),
            margin-top: (
              all: $ARTICLE_BOTTOM,
              mobileL: $ARTICLE_MOBILE_BOTTOM
            ),
            margin-right: (
              all: $ARTICLE_RIGHT,
              mobileL: $ARTICLE_MOBILE_RIGHT
              
            )
          )
        );
      }
    }
  }

  &_institutions {
    @include media(
      (
        margin-top: (
          all: -$INSTITUTION_BOTTOM,
          mobileL: -$INSTITUTION_MOBILE_BOTTOM
        ),
        margin-right: (
          all: -$INSTITUTION_RIGHT,
          mobileL: -$INSTITUTION_MOBILE_RIGHT
        ),
        width: (
          all: calc(100% + #{$INSTITUTION_RIGHT}),
          mobileL: calc(100% + #{$INSTITUTION_MOBILE_RIGHT})
        )
      )
    );

    .grid {
      &__item {
        @include media(
          (
            width: (
              all: 360px,
              mobileL: 100%
            ),
            margin-top: (
              all: $INSTITUTION_BOTTOM,
              mobileL: $INSTITUTION_MOBILE_BOTTOM
            ),
            margin-right: (
              all: $INSTITUTION_RIGHT,
              mobileL: $INSTITUTION_MOBILE_RIGHT
            )
          )
        );
      }
    }
  }
}
