@import '../../../css/guideline.scss';

.page[name='search'] {
  .wrapper {
    & > .section-title {
      margin-bottom: 16px;
    }

    & > .search {
      @include media(
        (
          margin-top: (
            all: 32px,
            tabletM: 24px,
            mobileL: 16px
          )
        )
      );
    }

    & > .content {
      @include media(
        (
          margin-top: (
            all: 24px,
            tabletM: 16px,
            mobileL: 16px
          )
        )
      );

      & > .typography {
        text-align: center;

        @include media(
          (
            margin-bottom: (
              all: 48px,
              tabletM: 32px,
              mobileL: 24px
            )
          )
        );

        span {
          font-weight: 500;
        }
      }
    }
  }
}
