@import '../../../css/guideline.scss';

.page[name='services'] {
  .page {
    &__content {
      @include media(
        (
          margin-top: (
            all: 48px,
            mobileL: 28px
          )
        )
      );
    }
  }
}
