@import '../../css/guideline.scss';

.region {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_preload {
    width: 100%;
    height: 24px;

    background: $preload;
  }

  &__count {
    min-width: 30px;

    text-align: right;
  }

  &_without-bold {
    .region {
      &__name {
        div {
          &:first-letter {
            font-size: inherit;
            font-weight: inherit;
          }
        }
      }
    }
  }

  &__name {
    display: flex;
    overflow: hidden;
line-height: 26px !important;
    div {
      overflow: hidden;

      white-space: nowrap;
      text-overflow: ellipsis;

      &:first-letter {
        font-size: 20px;
        font-weight: bold;
      }
    }

    i {
      align-items: center;

      margin-right: 4px;
    }

    p,
    span {
      line-height: normal !important;
    }
  }
}
