.Button {
  padding: 12px 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 1px solid #FFFFFF;
  border-radius: 6px;
  color: #fff;
  background-color: transparent;
  font-size: 15px;
  line-height: 24px;
  white-space: nowrap;
  text-decoration: none;
  user-select: none;
  cursor: pointer;

  &_hover, &:hover {
    text-decoration: none;
    outline: none;
  }

  &_focus, &:focus {
    text-decoration: none;
    outline: none;
  }

  &_disabled, &:disabled {
    opacity: 0.65;
  }

  &_theme {
    &_institution {
      width: calc(50% - 6px);
      font-size: 16px;
      line-height: 24px;
      color: #295FCC;
      padding: 8px 12px;
      background: #FFFFFF;
      border: 1px solid #A2B8E3;
      border-radius: 4px;
      &:hover{
        background-color: #EAF0FA;
      }
      &:not(:last-child) {
        margin-right: 12px;
      }
    }

    &_confirm {
      width: auto;
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      padding: 11px 24px;
      background: #295FCC;
      border-radius: 4px;
    }
  }

}
