.first__form{
    margin-bottom: 24px !important;
}

.error_start{
    flex-direction: column;
    align-items: flex-start;
}

.form__element_empty{
    @media(max-width:600px){
        margin:0;
    }
}

.form_group-items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    @media(max-width: 600px) {
        grid-template-columns: 1fr;
        gap: 12px;
    }
}