@import '../../../css/guideline.scss';

.sending-target-form {
  &__existing-contact{
    margin-top: 16px;
  }
  &__existing-contact-wrapper:nth-child(2n) > &__existing-contact{
    margin-left: 16px!important;
    @include media(
      (
        margin-left (
              mobileL: 0!important
          ),
      )
   )
  }
  &__existing-contact-wrapper:nth-child(2n+1) > &__existing-contact{
    margin-right: 16px!important;
    @include media(
      (
        margin-right (
              mobileL: 0!important
          ),
      )
   )
  }
  &__existing-contact-wrapper{
    width: 50%;

    @include media(
      (
        width (
              mobileL: 100%
          ),
      )
   )
  }
  &__existing-contacts-container{
    padding: 0;
    display: flex;
    flex-flow: row wrap;
  }
  &__button{
    margin-top: 16px;
    padding-left: 32px;
  }
  &__select-button{
    margin-top: 6px;
    padding-left: 0;
  }
  &__attention-text{
    margin: 16px 0 0;
  }
}