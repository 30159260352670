@import '../../../css/guideline.scss';

$SIDEBAR_WIDTH: 352px;

.page[name='institution'] {

  .is_mobile {
    @media(min-width: 1022px){
      display: none;
    }
  }

  .info {
    * {
      & + * {
        margin-top: 16px;
      }

      & + * + * {
        margin-top: 16px;
      }
    }
  }

  .page_preload {
    display: flex;

    & > div {
      display: flex;
    }

    .page {
      &__title {
        width: 300px;
        max-width: 80%;
        height: 40px;

        @extend %preloadStyle;
      }

      &__sidebar {
        width: $SIDEBAR_WIDTH;

        &-services {
          & > * {
            @extend %preloadStyle;
          }
        }

        &-navigation {
          @extend %preloadStyle;
          & > div {
            @extend %preloadStyle;
          }
        }

      }

      &__content {
        & > * {
          height: 66px;

          @extend %preloadStyle;
          &:first-child {
            height: 400px;

            @extend %preloadStyle;
          }
        }
      }
    }
  }
}


.page[name='institution'] {
  .page {
    &__title {
      @include media(
        (
          margin-bottom: (
            all: 32px,
            tabletM: 24px,
            mobileL: 16px
          )
        )
      );
    }

    &__sidebar-useful-articles-svg {
      width: 54px;
      height: 36px;
    }
    
    &__sidebar {
      width: 100%;

      &-tab {
        cursor: pointer;

        &.active {
          .typography {
            color: $lightText;
          }

          svg {
            path {
              fill: $lightText;
            }
          }
        }
      }

      & > * + * {
        @include media(
          (
            margin-top: (
              all: 24px,
              tabletM: 16px,
              mobileL: 16px
            )
          )
        );
      }

      &-useful-articles-item {
        margin-bottom: 24px;
      }
      
      &-useful-articles-item-text{
        padding-left: 12px;
      }

      &-services {
        & > * {
          @extend %cardStyle;

          & + * {
            @include media(
              (
                margin-top: (
                  all: 24px,
                  tabletM: 16px,
                  mobileL: 16px
                )
              )
            );
          }
        }
      }

      &-navigation {
        @extend %cardStyle;

        & > div {
          display: flex;
          align-items: center;

          @include media(
            (
              padding: (
                all: 0 24px,
                mobileL: 0 20px
              ),
              height: (
                all: 64px,
                mobileL: 56px
              )
            )
          );

          i {
            margin-right: 12px;
          }

          &:not(:last-child) {
            border-bottom: 1px solid $lightBorder;
          }
        }
      }
    }

    &__content {
      flex: 1;

      @include media(
        (
          margin-left: (
            all: 32px,
            tabletM: 0,
            mobileL: 0
          ),
          margin-top: (
            all: 0,
            tabletM: 32px,
            mobileL: 16px
          )
        )
      );

      & > .typography.warning {
        margin-top: 0;
      }

      .collapse {
        &_disabled {
          .collapse__title {
            padding-bottom: 0;
          }
        }

        &:not(.collapse_disabled) {
          table {
            td {
              display: table-cell;

              &:last-child {
                @include media(
                  (
                    text-align: (
                      mobileL: right
                    ),
                    justify-content: (
                      mobileL: flex-end
                    )
                  )
                );

                * {
                  @include media(
                    (
                      text-align: (
                        mobileL: right
                      ),
                      justify-content: (
                        mobileL: flex-end
                      )
                    )
                  );
                }
              }
            }

            tr:not(:first-child) {
              td {
                @include media(
                  (
                    padding-top: (
                      mobileL: 12px
                    )
                  )
                );
              }

              td {
                &:first-child {
                  @include media(
                    (
                      padding-right: (
                        mobileL: 16px
                      )
                    )
                  );
                }
              }
            }
          }
        }

        & + .collapse {
          @include media(
            (
              margin-top: (
                all: 24px,
                tabletM: 16px,
                mobileL: 16px
              )
            )
          );
        }

        &__content {
          & > * > * > * {
            & > * {
              & + .typography:not(.warning) {
                margin-top: 4px;
              }
            }

            & + * {
              margin-top: 24px;
            }

            & + .typography:not(.warning) {
              margin-top: 12px;
            }

            & + table {
              margin-top: 12px;
            }

            & + .document {
              margin-top: 36px;
            }
          }

          .document {
            & + .document {
              margin-top: 0;
            }
          }
        }
      }
      .comments{
        padding-top: 20px;
      }
    }
  }

  #sticky-container {
    display: flex;

    @include media(
      (
        flex-direction: (
          tabletM: column,
          mobileL: column
        )
      )
    );

    .sticky-outer-wrapper {
      @include media(
        (
          width: (
            all: $SIDEBAR_WIDTH,
            tabletM: 100%,
            mobileL: 100%
          ),
          order: (
            all: none,
            tabletM: 2,
            mobileL: 2
          ),
          margin-top: (
            all: 0,
            tabletM: $wrapperPaddingMobileM,
            mobileL: $wrapperPaddingMobileS
          )

        )
      );
    }
  }

  .document {
    display: flex;
    align-items: center;

    .typography {
      margin-right: 5px;
    }
  }

  .warning {
    color: $warningText;
    background: $warningBackground;

    @include media(
      (
        padding: (
          all: 20px 16px,
          mobileL: 8px 12px
        )
      )
    );

    &:not(:first-child) {
      @include media(
        (
          margin-top: (
            all: 24px,
            mobileL: 12px
          )
        )
      );
    }

    &:not(:last-child) {
      @include media(
        (
          margin-bottom: (
            all: 24px,
            mobileL: 16px
          )
        )
      );
    }
  }

  table {
    width: 100%;

    tbody,
    tbody tr {
      width: 100%;
    }

    &:not(.without-border) {
      tr {
        &:not(:last-child) {
          td {
            border-bottom: 1px solid $lightBorder;
          }
        }

        td {
          padding-bottom: 12px;
        }

        + tr {
          td {
            @include media(
              (
                padding-top: (
                  all: 12px,
                  mobileL: 8px
                )
              )
            );
            &:first-child {
              @include media(
                (
                  padding-top: (
                    all: 12px,
                    mobileL: 8px
                  )
                )
              );
            }
          }
        }
      }
    }

    tr {
      td {
        @include media(
          (
            display: (
              tabletM: block,
              mobileL: block
            )
          )
        );

        * + .link {
          margin-top: 4px;
        }
      }

      & + tr {
        td {
          @include media(
            (
              padding-top: (
                all: 24px,
                tabletM: 0,
                mobileL: 0
              )
            )
          );

          &:first-child {
            @include media(
              (
                padding-top: (
                  tabletM: 24px,
                  mobileL: 16px
                )
              )
            );
          }
        }
      }

      &:first-child {
        td {
          @include media(
            (
              padding-top: (
                all: 0px
              )
            )
          );
        }
      }
    }
  }

  .typography_type_text {
    &.typography_size_s {
      @include media(
        (
          font-size: (
            mobileL: 12px
          ),
          line-height: (
            mobileL: 16px
          )
        )
      );
    }

    &.typography_size_m {
      @include media(
        (
          font-size: (
            mobileL: 14px
          ),
          line-height: (
            mobileL: 20px
          )
        )
      );
    }
  }
}

.fl-col {
  &:not(.fl-col_only-row) {
    @include media(
      (
        padding-left: (
          all: 16px,
          tabletM: 0
        ),
        padding-right: (
          all: 16px,
          tabletM: 0
        )
      )
    );
  }

  &.fl-col_only-row {
    padding-right: 16px;
    padding-left: 16px;
  }

  &:first-child {
    padding-left: 0px;
  }

  &:last-child {
    padding-right: 0;
  }

  &-4 {
    @include media(
      (
        width: (
          all: 33.33%,
          tabletM: 50%
        )
      )
    );
  }

  &-6 {
    @include media(
      (
        width: (
          all: 50%
        ),
        min-width: (
          all: 50%
        )
      )
    );
  }

  &-8 {
    @include media(
      (
        width: (
          all: 66.66%,
          tabletM: 100%
        )
      )
    );
  }
}
