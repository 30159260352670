@import '../../css/guideline.scss';

.tab-slider {
  display: flex;

  width: 100%;

  &__tabs-title {  
    padding: 24px 0px 16px 16px;  
  }

  &_vertical {
    @include media(
      (
        flex-direction: (
          all: row,
          tabletM: column
        )
      )
    );

    ::-webkit-scrollbar {
      display: none;
    }

    .tab-slider {
      &__content {
        align-items: flex-start;
        justify-content: center;
      }

      &__tabs {
        @include media(
          (
            flex-direction: (
              all: column,
              tabletM: row
            ),
            width: (
              tabletM: 100vw
            ),
            overflow-x: (
              tabletM: scroll
            ),
            transform: (
              tabletM: translateX(-$wrapperPaddingTabletM),
              tabletS: translateX(-$wrapperPaddingTabletS),
              mobileL: translateX(-$wrapperPaddingMobileL),
              mobileM: translateX(-$wrapperPaddingMobileM),
              mobileS: translateX(-$wrapperPaddingMobileS)
            )
          )
        );

        &-wrapper {
          position: relative;

          display: flex;

          @include media(
            (
              flex-direction: (
                all: column,
                tabletM: row
              ),
              flex-grow: (
                tabletM: 1
              ),
              padding: (
                tabletM: 0 $wrapperPaddingTabletM,
                tabletS: 0 $wrapperPaddingTabletS,
                mobileL: 0 $wrapperPaddingMobileL,
                mobileM: 0 $wrapperPaddingMobileM,
                mobileS: 0 $wrapperPaddingMobileS
              )
            )
          );

          & > div {
            position: relative;
            // min-width: 100%;

            @include media(
              (
                display: (
                  tabletM: flex
                )
              )
            );

            &::after {
              position: absolute;
              z-index: 0;
              bottom: 0;
              left: 0;

              width: 100%;
              height: 1px;

              content: '';

              background: $border;

              @include media(
                (
                  display: (
                    all: none,
                    tabletM: flex
                  )
                )
              );
            }

            & > div {
              position: relative;
              z-index: 10;

              white-space: nowrap;

              @include media(
                (
                  padding: (
                    all: 0 0 0 16px,
                    tabletM: 0px 20px 10px
                  ),
                  border-left: (
                    all: 4px solid transparent,
                    tabletM: none
                  ),
                  border-bottom: (
                    all: none,
                    tabletM: 2px solid transparent
                  ),
                  height: (
                    all: 60px,
                    tabletM: auto
                  )
                )
              );

              &.active {
                @include media(
                  (
                    border-width: (
                      all: 4px,
                      tabletM: 2px
                    )
                  )
                );
              }
            }
          }
        }
      }

      &__content {
        display: flex;
        flex-wrap: wrap;

        width: 100%;

        @include media(
          (
            margin-left: (
              all: 100px,
              tabletM: 0
            ),
            margin-top: (
              all: 0,
              tabletM: 32px
            )
          )
        );

        &-wrapper {
          display: flex;
          flex-direction: column;

          width: 100%;

          & > div {
            display: flex;

            @include media(
              (
                flex-direction: (
                  tabletM: column
                )
              )
            );

            & + div {
              margin-top: 16px;
            }

            .link:not(.doc) {
              display: flex;
              flex: 1;
              justify-content: space-between;

              @include media(
                (
                  max-width: (
                    all: calc(50% - 16px),
                    tabletM: none
                  )
                )
              );

              & + .link {
                @include media(
                  (
                    margin-top: (
                      all: 0,
                      tabletM: 16px
                    ),
                    margin-left: (
                      all: 32px,
                      tabletM: 0
                    )
                  )
                );
              }
            }
          }
        }
      }
    }
  }

  &_horizontal {
    overflow: -moz-scrollbars-none;
    flex-direction: column;

    -ms-overflow-style: none;
    
    ::-webkit-scrollbar {
      display: none;

      width: 0 !important;
      height: 0 !important;
    }

    .tab-slider {
      &__content {
        align-items: flex-start;
        justify-content: flex-start;

        @include media(
          (
            margin-top: (
              all: 32px,
              mobileL: 16px
            )
          )
        );
      }

      &__tabs {
        position: relative;

        overflow-x: scroll;
        flex-direction: row;

        width: 100vw;

        @include media(
          (
            transform: (
              all: translateX(calc((100vw - #{$maxWidthWrapper}) / -2)),
              tabSliderPoint: translateX(-$wrapperPaddingDesktopS),
              tabletM: translateX(-$wrapperPaddingTabletM),
              tabletS: translateX(-$wrapperPaddingTabletS),
              mobileL: translateX(-$wrapperPaddingMobileL),
              mobileM: translateX(-$wrapperPaddingMobileM),
              mobileS: translateX(-$wrapperPaddingMobileS)
            )
          )
        );

        &-wrapper {
          position: relative;

          display: flex;
          flex-grow: 1;

          @include media(
            (
              padding: (
                all: 0 calc((100vw - #{$maxWidthWrapper}) / 2),
                tabSliderPoint: 0 $wrapperPaddingDesktopS,
                tabletM: 0 $wrapperPaddingTabletM,
                tabletS: 0 $wrapperPaddingTabletS,
                mobileL: 0 $wrapperPaddingMobileL,
                mobileM: 0 $wrapperPaddingMobileM,
                mobileS: 0 $wrapperPaddingMobileS
              )
            )
          );

          & > div {
            position: relative;

            display: flex;

            min-width: 100%;

            &::after {
              position: absolute;
              z-index: 0;
              bottom: 0;
              left: 0;

              display: flex;

              width: 100%;
              height: 1px;

              content: '';

              background: $border;
            }

            & > div {
              position: relative;
              z-index: 10;

              padding: 0px 20px 10px;

              white-space: nowrap;

              border-bottom: 2px solid transparent;
              &.active {
                border-width: 2px;
              }
            }
          }
        }
      }
    }
  }

  &__tabs {
    display: flex;

    &-wrapper {
      & > div {
        & > div {
          display: flex;
          align-items: center;

          cursor: pointer;
          transition: $transition;

          &:not(.active) {
            color: $text;
          }

          &.active {
            border-color: $main;
          }
        }
      }
    }
  }
}
