@import '../../../css/guideline.scss';

.page[name='guide-mail'] {
  .page {
    &__content {
      width: 100%;

      @include media(
        (
          margin-top: (
            all: 48px,
            mobileL: 32px,
            mobileS: 24px
          )
        )
      );

      .step {
        @extend %cardStyle;
        @include media(
          (
            padding: (
              all: 48px,
              mobileL: 32px,
              mobileS: 24px
            )
          )
        );

        .step {
          &__head {
            display: flex;
            align-items: center;

            & > :first-child {
              display: flex;
              align-items: center;
              justify-content: center;

              width: 48px;
              min-width: 48px;
              height: 48px;
              margin-right: 24px;

              border-radius: 48px;
              background: $main;
            }
          }

          &__body {
            display: flex;

            margin-top: 24px;

            @include media(
              (
                flex-direction: (
                  tabletM: column-reverse
                )
              )
            );

            .html {
              display: flex;
              flex-direction: column;

              max-width: 320px;

              @include media(
                (
                  max-width: (
                    all: 320px,
                    tabletM: none
                  )
                )
              );

              * + * {
                margin-top: 12px;
              }
            }

            &-image {
              @include media(
                (
                  max-width: (
                    all: 672px,
                    tabletM: none
                  ),
                  margin-left: (
                    all: 32px,
                    tabletM: 0
                  ),
                  margin-bottom: (
                    tabletM: 32px
                  )
                )
              );

              img {
                width: 100%;
              }
            }

            & + .step__body {
              margin-top: 48px;
            }
          }
        }

        & + .step {
          margin-top: 24px;
        }
      }
    }
  }
}
